import repo from "./repository";
import { PurchaseTypeModel ,ParameterModel} from "model";
export default class PurchaseTypeRepository {
  async find(id: string): Promise<PurchaseTypeModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "PurchaseType/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,PurchaseTypeModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "PurchaseType", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "PurchaseType/" + id)
      .then((res) => res?.data);
  }
  async save(entity: PurchaseTypeModel): Promise<PurchaseTypeModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "PurchaseType", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: PurchaseTypeModel): Promise<PurchaseTypeModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "PurchaseType/" + id, entity)
      .then((response) => response.data);
  }
}
