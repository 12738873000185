import { useCallback, useContext } from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import GridAction from 'common/component/gridAction/gridAction'

import { BankTransactionContext } from '../bankTransaction'

function BankTransactionList(props: { setShowForm: Function }) {
  const { setShowForm } = props
  const {
    transactionDetails,
    setTransactionDetails,
    setTransactionDetailId,
    transactionType,
  } = useContext(BankTransactionContext)

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'sourceName',
      key: 'sourceName',
      render: (text, record) => (
        <Link
          to={''}
          onClick={() => {
            editHandler(record.id)
            setShowForm(true)
          }}
        >
          <span className="font-semibold">{text}({record.accountType})</span>
        
        </Link>
      ),
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (text, record) => (
        <GridAction
          id={record.id}
          record={record}
          showAdd={false}
          editHandler={() => {
            editHandler(record.id)
            setShowForm(true)
          }}
          deleteHandler={deleteHandler}
        ></GridAction>
      ),
    },
  ]

  const deleteHandler = useCallback(
    (id: string) => {
      const newDataState = transactionDetails.filter((obj: any) => obj.id != id)
      setTransactionDetails(newDataState)
    },
    [transactionDetails],
  )
  const editHandler = useCallback(
    (id: string) => {
      setTransactionDetailId(id)
    },
    [transactionDetails],
  )
  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={transactionDetails}
        pagination={false}
      />
    </>
  )
}
export default BankTransactionList
