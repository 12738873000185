import repo from "./repository";
import { DeliveryModel ,ParameterModel, VatReportModel} from "model";
export default class DeliveryRepository {
  async find(id: string): Promise<DeliveryModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Delivery/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,DeliveryModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Delivery", { params: param })
      .then((res) => res?.data);
  }
  async findAllSales(param: ParameterModel): Promise<[number,DeliveryModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Delivery/Sales", { params: param })
      .then((res) => res?.data);
  }
  async findSalesTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Delivery/SalesTotal", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Delivery/" + id)
      .then((res) => res?.data);
  }
  async deleteSales(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Delivery/Sales/" + id)
      .then((res) => res?.data);
  }
  async save(entity: DeliveryModel): Promise<DeliveryModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Delivery", entity)
      .then((response) => response.data);
  }
  async saveSales(entity: DeliveryModel): Promise<DeliveryModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Delivery/Sales", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: DeliveryModel): Promise<DeliveryModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Delivery/" + id, entity)
      .then((response) => response.data);
  }
  async post(id: string): Promise<DeliveryModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Delivery/Post/"+id)
      .then((response) => response.data);
  }
  async unPost(id: string): Promise<DeliveryModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Delivery/UnPost/"+id)
      .then((response) => response.data);
  }
}
