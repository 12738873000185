import { useQuery } from "react-query";

import repository from "service";
import {  ParameterModel } from "model";

const reportRepository = new repository.ReportRepository();

const fetchData = async (param: any): Promise<any[]> => {
  return await reportRepository.findAllFuelTransaction(param)
};

export function useFuelTransactions(param: ParameterModel) {
  var {
    data,
    isLoading,
    error,
  } = useQuery(["reports", param], () => fetchData(param), {
   /*enabled:
      (typeof param.searchText != "undefined" && param.searchText != "") ||
      (typeof param.categoryId != "undefined" && param.categoryId != "") ||
      (typeof param.category != "undefined" && param.category != ""),*/
  });

  return { data, isLoading, error };
}
