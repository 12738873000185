
function Message(props: { type: string; message: string }) {
  const {message,type}=props
  return (
    <div>
      {props.type == 'error' && (
        <div className="errorMessage">
          <div style={{fontSize:11,fontStyle:'italic'}}
            dangerouslySetInnerHTML={{ __html: message as string}}
          ></div>
        </div>
      )}
      {props.type == 'success' && (
        <div className="successMessage">
       <div style={{fontSize:11,fontStyle:'italic'}}
            dangerouslySetInnerHTML={{ __html: message as string}}
          ></div>
        </div>
      )}
    </div>
  )
}

export default Message
