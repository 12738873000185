import repo from "./repository";
import { TinModel ,ParameterModel} from "model";
export default class TinRepository {
  async find(id: string): Promise<TinModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Tin/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,TinModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Tin", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Tin/" + id)
      .then((res) => res?.data);
  }
  async save(entity: TinModel): Promise<TinModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Tin", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: TinModel): Promise<TinModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Tin/" + id, entity)
      .then((response) => response.data);
  }
}
