import { Button } from 'antd'

import { COLORS } from 'assets/stlyes/theme'
import { MouseEventHandler } from 'react'
import Icon from '../icons/customIcon'
import ButtonField from 'common/ui/ButtonField'

interface Props {
  onAdd?: MouseEventHandler<HTMLElement>
  onEdit?: MouseEventHandler<HTMLElement>
  onaddChild?: MouseEventHandler<HTMLElement>
  onList?: MouseEventHandler<HTMLElement>
  onAction?: MouseEventHandler<HTMLElement>
  title: string
  showEdit?: boolean
  showAddChild?: boolean
  showForm?: boolean
  showAction?: boolean
  actionName?: string
  showBack?: boolean
  hideAdd?:boolean
}
function FormNavBar(props: Props) {
  const {
    onAdd,
    onList,
    onAction,
    showBack,
    title,
    showAction,
    showForm,
    actionName,
    hideAdd
  } = props

  return (
    <div className=" pt-2  pl-2 mr-3">
      <div className="ml-2 flex justify-between">
        <div className="pb-0">
     
          {(showForm || showAction) && !showBack && (
            <ButtonField
              value=""
              style={{
                 border: 0,
                 height:0,
              }}
              onClick={onList}
              icon="CaretLeftOutlined"
            />
          )}
          <div className=" text-xl font-bold  text-slate-500  pl-2 pt-0">
          {title}
          </div>

        </div>

        <div className="justify-end">
          {' '}
          {showAction && !showForm && showBack && (
            <ButtonField
              style={{
                background: COLORS.white,
                color: COLORS.green,
                border: 1,
              }}
              onClick={onAction}
              value={actionName}
              icon="CheckCircleFilled"
            />
          )}
          {(!showForm || (showAction && showBack)) && !hideAdd && (
            <ButtonField
              style={{
                background: COLORS.white,
                color: COLORS.green,
                border: 2,
                borderColor:COLORS.lightGray3
              }}
              onClick={onAdd}
              value="New"
              icon="PlusSquareFilled"
            />
          )}

        </div>
      </div>
    </div>
  )
}

export default FormNavBar
