import { useQuery } from "react-query";

import repository from "service";
import { CustomerModel,ParameterModel } from "model";

const customerRepository = new repository.CustomerRepository();
let records: CustomerModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[CustomerModel[], number]> => {
  await customerRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useCustomers(param: ParameterModel) {
  var {
    data: customerData,
    isLoading,
    error,
  } = useQuery(["customers", param], () => fetchData(param), {});
  var data = customerData?.[0];
  var recordCount = customerData?.[1];

  return { data, recordCount, isLoading, error };
}
