import { Tooltip } from 'antd'
import { COLORS } from 'assets/stlyes/theme'
import ButtonField from 'common/ui/ButtonField'
import ConfirmField from 'common/ui/ConfirmField'

interface Props {
  editHandler?: Function
  deleteHandler?: Function
  addHandler?: Function
  postHandler?: Function
  unPostHandler?: Function
  transactionHandler?:Function
  actionHandler?:Function
  id: string
  record?: any
  showAdd?: boolean
  showEdit?: boolean
  showDelete?: boolean
  showPost?: boolean
  showUnPost?: boolean
  showTransaction?:boolean
  showAction?:boolean
  actionName?:string
}
function GridAction(props: Props) {
  const {
    editHandler,
    deleteHandler,
    actionHandler,
    id,
    showAction,
    actionName,
    showEdit,
    showTransaction,
    transactionHandler,
    showDelete,
    showUnPost,
    unPostHandler,
    showPost,
    showAdd,
    addHandler,
    postHandler,
    record,
  } = props

  return (
    <div className="">
      <>
        {showAdd && (
          <Tooltip title={'Add Child'}>
            {' '}
            <ButtonField
              value={''}
              icon={'PlusCircleOutlined'}
              style={{
                padding: 0,
                color: COLORS.green,
                border: 0,
              }}
              onClick={(e) => {
                addHandler?.(record)
              }}
            ></ButtonField>
          </Tooltip>
        )}
            {showAction && (
          <Tooltip title={actionName}>
            {' '}
            <ButtonField
              value={''}
              icon={'CheckCircleOutlined'}
              style={{
                color: COLORS.green,
                padding: 0,
                border: 0,
              }}
              onClick={(e) => {
               actionHandler?.(id)
              }}
            ></ButtonField>
          </Tooltip>
        )}
        {showPost && (
          <Tooltip title={'Post'}>
            {' '}
            <ButtonField
              value={''}
              icon={'CheckCircleOutlined'}
              style={{
                color: COLORS.green,
                padding: 0,
                border: 0,
              }}
              onClick={(e) => {
                ConfirmField({
                  title: 'Post',
                  content: 'Are you sure you want to Post the record?',
                  data: id,
                  onOk: () => {
                    postHandler?.(id)
                  },
                })
              }}
            ></ButtonField>
          </Tooltip>
        )}
                {showUnPost && (
          <Tooltip title={'Un Post'}>
            {' '}
            <ButtonField
              value={''}
              icon={'CloseCircleOutlined'}
              style={{
                color: COLORS.fourth,
                padding: 0,
                border: 0,
              }}
              onClick={(e) => {
                ConfirmField({
                  title: 'Un Post',
                  content: 'Are you sure you want to un Post the record?',
                  data: id,
                  onOk: () => {
                    unPostHandler?.(id)
                  },
                })
              }}
            ></ButtonField>
          </Tooltip>
        )}
        {showEdit && (
          <Tooltip title={'Edit'}>
            {' '}
            <ButtonField
              value={''}
              icon={'EditOutlined'}
              style={{
                padding: 0,
                color: COLORS.fifth,
                border: 0,
              }}
              onClick={(e) => {
                editHandler?.(id)
              }}
            ></ButtonField>
          </Tooltip>
        )}
              {showTransaction && (
          <Tooltip title={'Transaction'}>
            {' '}
            <ButtonField
              value={''}
              icon={'DeliveredProcedureOutlined'}
              style={{
                color: COLORS.green,
                padding: 0,
                border: 0,
              }}
              onClick={(e) => {
               transactionHandler?.(id)
              }}
            ></ButtonField>
          </Tooltip>
        )}
      
        {showDelete && (
          <Tooltip title={'Delete'}>
            {' '}
            <ButtonField
              value={''}
              icon={'DeleteOutlined'}
              style={{
                color: COLORS.red,
                padding: 0,
                border: 0,
              }}
              onClick={(e) => {
                ConfirmField({
                  title: 'Delete',
                  content: 'Are you sure you want to delete the record?',
                  data: id,
                  onOk: () => {
                    deleteHandler?.(id)
                  },
                })
              }}
            ></ButtonField>
          </Tooltip>
        )}
      </>
    </div>
  )
}
GridAction.defaultProps = {
  showAdd: false,
  showEdit: true,
  showDelete: true,
  showPost: false,
  showUnPost:false
}

export default GridAction
