import { useQuery } from "react-query";

import repository from "service";
import { ExpenseModel, ParameterModel } from "model";

const expenseRepository = new repository.ExpenseRepository();
let records: ExpenseModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[ExpenseModel[], number]> => {
  await expenseRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useExpenses(param: ParameterModel) {
  var {
    data: expenseData,
    isLoading,
    error,
  } = useQuery(["expenses", param], () => fetchData(param), {
   /*enabled:
      (typeof param.searchText != "undefined" && param.searchText != "") ||
      (typeof param.categoryId != "undefined" && param.categoryId != "") ||
      (typeof param.category != "undefined" && param.category != ""),*/
  });
  var data = expenseData?.[0];
  var recordCount = expenseData?.[1];

  return { data, recordCount, isLoading, error };
}
