import { useQuery } from "react-query";

import repository from "service";
import { StoreModel,ParameterModel } from "model";

const storeRepository = new repository.StoreRepository();
let records: StoreModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[StoreModel[], number]> => {
  await storeRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useStores(param: ParameterModel) {
  var {
    data: storeData,
    isLoading,
    error,
  } = useQuery(["stores", param], () => fetchData(param), {});
  var data = storeData?.[0];
  var recordCount = storeData?.[1];

  return { data, recordCount, isLoading, error };
}
