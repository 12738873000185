/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialState {
  product: {id:string,name:string,stockRemark?:string};
}
const initialState: initialState = {
  product:{id:'',name:''},
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    set(
      state,
      { payload }: PayloadAction<{ id: string; name: string,stockRemark?:string }>
    ) {
      state.product.id = payload.id;
      state.product.name = payload.name;
      state.product.stockRemark = payload.stockRemark;
    },
    reset(state) {
      state.product.id = "";
      state.product.name = "";
    },
  },
});

export const { set, reset } = productSlice.actions;
export default productSlice.reducer;
