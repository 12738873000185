import repo from "./repository";
import { MachineModel ,ParameterModel} from "model";
export default class MachineRepository {
  async find(id: string): Promise<MachineModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Machine/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,MachineModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Machine", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Machine/" + id)
      .then((res) => res?.data);
  }
  async save(entity: MachineModel): Promise<MachineModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Machine", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: MachineModel): Promise<MachineModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Machine/" + id, entity)
      .then((response) => response.data);
  }
}
