import { BankTransactionModel } from "model";
import { useMutation, UseMutateFunction } from "react-query";
import repository from "service";

interface updateResult {
  mutate: UseMutateFunction<BankTransactionModel, unknown, any, unknown>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: any;
}

const bankTransactionRepository = new repository.BankTransactionRepository();

const saveBankTransaction = async (
  bankTransaction: BankTransactionModel
): Promise<BankTransactionModel> => {
  return await (typeof bankTransaction.id != "undefined" && bankTransaction.id != ""
    ? bankTransactionRepository.update(bankTransaction.id as string, bankTransaction)
    : bankTransactionRepository.save(bankTransaction));
};

export function useSaveBankTransaction(): updateResult {
  const { mutate, isLoading, isError, error, isSuccess } =
    useMutation(saveBankTransaction);

  var result: updateResult = { mutate, isLoading, isError, error, isSuccess };
  return result;
}
