import { BankTransactionModel } from "model";
import { useMutation ,UseMutateFunction} from "react-query";
import repository from "service";

interface updateResult {
  mutate: UseMutateFunction<BankTransactionModel, unknown, any, unknown>
  isLoading: boolean
  isError: boolean
}

const bankTransactionRepository = new repository.BankTransactionRepository();

const deleteBankTransaction =  async (id: any): Promise<BankTransactionModel> => {
  return await bankTransactionRepository.delete(id);
};

export function useDeleteBankTransaction():updateResult {
 const {mutate,  isLoading, isError } = useMutation(deleteBankTransaction)

var result: updateResult = {mutate,isLoading,isError}  ;
return result;
}
