import repo from "./repository";
import { ConnectionStringModel, DataSourceObjectModel ,ParameterModel} from "model";
export default class DataSourceObjectRepository {
  async find(id: string): Promise<DataSourceObjectModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSourceObject/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,DataSourceObjectModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSourceObject", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "DataSourceObject/" + id)
      .then((res) => res?.data);
  }
  async save(entity: DataSourceObjectModel): Promise<DataSourceObjectModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "DataSourceObject", entity)
      .then((res) => res?.data);
  }
  async update(id: string, entity: DataSourceObjectModel): Promise<DataSourceObjectModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "DataSourceObject/" + id, entity)
      .then((res) => res?.data);
  }
}
