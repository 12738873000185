import repo from "./repository";
import { DailySalesModel ,ParameterModel} from "model";
export default class DailySalesRepository {
  async find(id: string): Promise<DailySalesModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DailySales/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,DailySalesModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DailySales", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "DailySales/" + id)
      .then((res) => res?.data);
  }
  async save(entity: DailySalesModel): Promise<DailySalesModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "DailySales", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: DailySalesModel): Promise<DailySalesModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "DailySales/" + id, entity)
      .then((response) => response.data);
  }
}
