import repo from "./repository";
import { ConnectionStringModel, DataSourceModel ,DataSourceObjectFieldModel,ParameterModel} from "model";
export default class DataSourceRepository {
  async find(id: string): Promise<DataSourceModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSource/" + id)
      .then((res) => res?.data);
  }
  async getDatabase(connString: ConnectionStringModel): Promise<string[]> {
    console.log(connString)
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSource/Database" , { params:connString})
      .then((res) => res?.data);
  }
  async getObject(connString: ConnectionStringModel): Promise<string[]> {
    console.log(connString)
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSource/DatabaseObject" , { params:connString})
      .then((res) => res?.data);
  }
  async getObjectField(connString: ConnectionStringModel): Promise<DataSourceObjectFieldModel[]> {
    console.log(connString)
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSource/DatabaseObjectField" , { params:connString})
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,DataSourceModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSource", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "DataSource/" + id)
      .then((res) => res?.data);
  }
  async save(entity: DataSourceModel): Promise<DataSourceModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "DataSource", entity)
      .then((res) => res?.data);
  }
  async update(id: string, entity: DataSourceModel): Promise<DataSourceModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "DataSource/" + id, entity)
      .then((res) => res?.data);
  }
}
