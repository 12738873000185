import repo from "./repository";
import { ProductCategoryModel, ParameterModel } from "model";
export default class ProductCategoryRepository {
  async find(id: string): Promise<ProductCategoryModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "ProductCategory/" + id)
      .then((res) => res?.data);
  }
  async findAll(
    param: ParameterModel
  ): Promise<[number, ProductCategoryModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "ProductCategory", { params: param })
      .then((res) => res?.data);
  }
  async findAllTree(param: ParameterModel): Promise<ProductCategoryModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "ProductCategory/GetWithChildren", {
        params: param,
      })
      .then((res) => res?.data);
  }
  async findAllTreeByTitle(
    param: ParameterModel
  ): Promise<ProductCategoryModel[]> {
    return await repo
      .get(
        process.env.REACT_APP_API_URL +
          "ProductCategory/GetWithChildrenByTitle",
        { params: param }
      )
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "ProductCategory/" + id)
      .then((res) => res?.data);
  }
  async save(entity: ProductCategoryModel): Promise<ProductCategoryModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "ProductCategory", entity)
      .then((response) => response.data);
  }
  async update(
    id: string,
    entity: ProductCategoryModel
  ): Promise<ProductCategoryModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "ProductCategory/" + id, entity)
      .then((response) => response.data);
  }
}
