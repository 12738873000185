
const parameterInitialData = {
    offset: 0,
    limit: 0,
    orderField: "",
    orderDir: "",
    searchText: "",
    categoryId: "",
    category: "",
    groupId:'',
    group:'',
    companyId:''
  }
  export  {parameterInitialData}