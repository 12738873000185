import repo from "./repository";
import { PriceChangeModel ,ParameterModel} from "model";
export default class PriceChangeRepository {
  async find(id: string): Promise<PriceChangeModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "PriceChange/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,PriceChangeModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "PriceChange", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "PriceChange/" + id)
      .then((res) => res?.data);
  }
  async save(entity: PriceChangeModel): Promise<PriceChangeModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "PriceChange", entity)
      .then((response) => response.data);
  }
  async saveBulk(entitys: PriceChangeModel[]): Promise<PriceChangeModel[]> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "PriceChange/BulkUpdate", entitys)
      .then((response) => response.data);
  }
  async update(id: string, entity: PriceChangeModel): Promise<PriceChangeModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "PriceChange/" + id, entity)
      .then((response) => response.data);
  }
}
