import { EventHandler, memo } from 'react'
import { Avatar, List, MenuProps, Skeleton, Tooltip } from 'antd'
import { v4 as uuid } from 'uuid'
import Icon from '../icons/customIcon'
import { Button } from 'antd/es/radio'
import { Link } from 'react-router-dom'
import ConfirmField from 'common/ui/ConfirmField'

const SimpleList = memo(function (props: {
  dataSource: []
  summary: string
  defaultPageSize: number
  loading?: boolean
  onLoadMore?: Function
  showFuelTransaction?: boolean
  deleteHandler?: EventHandler<any>
  bincardHandler?: Function
  adjustmentHandler?: Function
  priceChangeHandler?: Function
  fuelTransactionHandler?: Function
  editHandler?: Function
  showDelete?:boolean
}) {
  const {
    dataSource,
    loading,
    showFuelTransaction,
    deleteHandler,
    fuelTransactionHandler,
    summary,
    onLoadMore,
    editHandler,
    showDelete
  } = props

  const items: MenuProps['items'] = [
    {
      label: (
        <span className="ml-4 text-xs font-normal text-end">
          <span className=" text-red-600 ">
            {' '}
            <Icon style={{ fontSize: 11 }} icon="DeleteFilled"></Icon>{' '}
          </span>
          Delete
        </span>
      ),
      key: '1',
    },
  ]

  return (
    <div key={uuid()} style={{ padding: 5 }}>
      {dataSource.length > 0 && (
        <List
          id={uuid()}
          className="p-2"
          footer={
            <div>
              {summary.length > 0 && (
                <div className=" flex justify-end p-1">
                  <span className="text-[14px] font-medium text-slate-600 p-2">
                    <span>{summary}</span>
                    <div>
                      <b className=" text-slate-300 text-[14px] font-medium">
                        Summary{'  '}
                      </b>{' '}
                    </div>
                  </span>
                </div>
              )}
            </div>
          }
        >
          {dataSource.map((item: any, index: number) => (
            <List.Item key={item.id} className="border-0 p-2">
              <List.Item.Meta
                title={
                  <Link onClick={() => editHandler?.(item.id)} to={''}>
                    <span className="text-[14px] font-medium">{item.name}</span>{' '}
                    <span className="text-[12px]  text-slate-400">
                      {' '}
                      {item.code}
                    </span>
                  </Link>
                }
                description={item.description}
              />
              <div >
             <div key={uuid()} className="mr-4 ">
               {item.quantity}
                </div>
             
                <div key={uuid()} className="mr-4 ">
                {item.total}
                </div>
                {showFuelTransaction && (
                  <Tooltip title={'Fuel Tank'}>
                    <Button
                      onClick={() => {
                        fuelTransactionHandler?.(item)
                      }}
                      className="border-0 bg-transparent  text-green-500"
                    >
                      <Icon icon="HourglassOutlined"></Icon>
                    </Button>
                  </Tooltip>
                )}
               {showDelete!=false && <Tooltip title={'Delete'}>
                  <Button
                    onClick={() => {
                      ConfirmField({
                        title: 'Delete',
                        content: 'Are you sure you want to delete the record?',
                        data: item.id,
                        onOk: () => {
                          deleteHandler?.(item.id)
                        },
                      })
                    }}
                    className="border-0 bg-transparent  text-red-600"
                  >
                    <Icon icon="DeleteOutlined"></Icon>
                  </Button>
                </Tooltip>}
              </div>
            </List.Item>
          ))}
        </List>
      )}
      {loading && (
        <>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
          <Skeleton avatar paragraph={{ rows: 1 }} active />
          <Skeleton avatar paragraph={{ rows: 1 }} active />
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </>
      )}
    </div>
  )
})

export default SimpleList
