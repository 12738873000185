import { useEffect, useState } from 'react'
import {  Table } from 'antd'
import { useSelector } from 'react-redux'

import { useUserSelector } from 'state/hook'
import { parameterInitialData } from 'constants/index'
import { ColumnsType } from 'antd/es/table'
import { useStores } from 'inventory/features/store/hooks/useStores'
import SearchBar from 'common/component/searchBar/searchBar'
import { useTins } from 'vat/features/tin/hooks/useTins'
import { useSalesOrders } from 'Sales/features/SalesOrder/hooks/useSalesOrders'
import { useCustomers } from 'Sales/features/customer/hooks/useCustomers'

function SalesByVehicleReport() {
  const { user } = useSelector(useUserSelector)
  const [param, setParam] = useState({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const [detailSales, setDetailSales] = useState([])
  const { data: customerList, isLoading: customerLoading } = useCustomers(param)
  const { data: storeList } = useStores({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: tinList } = useTins({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: salesOrderList, isLoading: salesOrderLoading } = useSalesOrders({
    ...param,
  })
  useEffect(() => {
    var results = salesOrderList?.reduce((result, record) => {
      record?.items?.map((item: any) =>
        result.push({
          ...item,
          total: item.unitPrice * item.quantity,
          ...record
        } as never),
      )
      return result
    }, [])

    setDetailSales(results as never)
  }, [salesOrderList])

  const dipReadingColumns: ColumnsType<any> = [
   
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
     },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
     },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
     },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ]

  const searchHandler = () => {}
  const searchTextChangeHandler = (e: any) => {
    const { name, value } = e.target
    setParam((prevState) => ({
      ...prevState,
      searchText: value,
    }))
  }

  return (
    <div className="p-2">
      <div style={{ height: 0 }}></div>
      <SearchBar
        param={param}
        setParam={setParam}
        categoryData={customerList}
        groupData={tinList}
        storeData={storeList}
        submitHandler={searchHandler}
        textChangeHnadler={searchTextChangeHandler}
      ></SearchBar>
      <div className="font-bold m-2 text-lg "> Sales By Vehicle Report</div>
      <Table
        columns={dipReadingColumns}
        rowKey="id"
        dataSource={detailSales}
        loading={salesOrderLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10} className="font-bold">
                {Number(
                  detailSales?.reduce(
                    (sum:any, a:any) =>
                      sum + (a.total as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
export default SalesByVehicleReport
