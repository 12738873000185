import { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Row, Col } from 'antd'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { SalesContext } from '../sales'
import { useUserSelector } from 'state/hook'
import { DeliveryItemModel } from 'model'
import InputField from 'common/ui/inputField'
import SelectField from 'common/ui/selectField'
import { parameterInitialData } from 'constants/index'
import { useQueryClient } from 'react-query'
import { useProductsSearch } from 'inventory/features/product/hooks/useProductsSearch'
import ButtonField from 'common/ui/ButtonField'
import { COLORS } from 'assets/stlyes/theme'
import MessageField from 'common/ui/messageField'

function SalesItemForm(props:{setShowForm:Function}) {
  const {setShowForm}=props
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const { user } = useSelector(useUserSelector)

  const initialState = {
    companyId: user?.companyId,
    createdBy: user?.firstName + '' + user?.lastName,
    updatedBy: user?.firstName + '' + user?.lastName,
  } as DeliveryItemModel

  const [formData, setFormtData] = useState(initialState)
  const {
    salesItemId,
    salesitems,
    setItems,
    setItemId,
  } = useContext(SalesContext)
  const [param, setParam] = useState({...parameterInitialData,companyId:user?.companyId})
  const { data: productList, isLoading: productLoading } = useProductsSearch(
    param,
  )

  useEffect(() => {
    if (
      typeof salesItemId != 'undefined' &&
      salesItemId != ''
    ) {
      var item = salesitems.filter(
        (a) => a.id == salesItemId,
      )?.[0]
      setFormtData(item)
      form.setFieldsValue(item)
    } else {
      resetHandler()
    }
  }, [salesItemId])

  const changeHandler = useCallback((e: any) => {
    const { name, value } = e.target
    setFormtData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const selectProductHandler = useCallback(
    (value: any) => {
      var record = productList?.filter((a) => a.id == value)?.[0]
      setFormtData((prevState) => ({
        ...prevState,
        productId: value,
        product: record?.name,
        availableQuantity:record?.quantity
      }))
    },
    [productList],
  )
  const onProductSearch = useCallback((value: any) => {
    setParam((prevState) => ({
      ...prevState,
      searchText: value,
    }))
  }, [])

  const resetHandler = useCallback(() => {
    setFormtData(initialState)
    form.resetFields()
    form.setFieldsValue(initialState)
    setItemId('')
  }, [])
  const add = async () => {
    var isDataExist = false
    if (
      (formData?.availableQuantity as number) < (formData.quantity as number)
    ) {
      MessageField({
        content: 'there is no sufficient balance in the store',
        type: 'error',
      })
      return;
    }
    const newDataState = salesitems.map((obj: any) => {
      if (obj.id === formData.id) {
        isDataExist = true
        return formData
      }
      return obj
    })
    if (isDataExist == false) {
      formData.id = uuid()
      newDataState.push(formData)
    }
    setItems(newDataState)
    resetHandler()
    setShowForm(false)
  }
  const submitHandler = async () => {
    await form.validateFields().then(() => {
      {
        add()
      }
    })
  }
  return (
    <>
      <div style={{ height: 0 }}></div>
      <div className="p-2 ">
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          autoComplete="off"
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <SelectField
                name="product"
                label="Product"
                placeholder="Product"
                value={formData.product}
                onChange={selectProductHandler}
                onSearch={onProductSearch}
                isRequired={true}
                loading={productLoading}
                dataOptions={
                  productList?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                      description: item.partNumber,
                      quantity:item.quantity
                    }
                  }) as never
                }
              />
              <Form.Item style={{ margin: '0 0 10px' }}>
                <Row>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>

                  <Col xs={11} sm={11} md={9} lg={9} xl={9}>
                    <InputField
                      type={'number'}
                      name="quantity"
                      label="Quantity"
                      placeholder="Quantity"
                      value={String(formData.quantity)}
                      onChange={changeHandler}
                      isRequired={true}
                    />
                  </Col>
                  <Col xs={2} sm={2} md={1} lg={1} xl={1}></Col>

                  <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                    <InputField
                      type={'number'}
                      name="unitPrice"
                      label="Unit Price"
                      placeholder="Unit Price"
                      value={String(formData.unitPrice)}
                      onChange={changeHandler}
                      isRequired={true}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col offset={2} xs={24} sm={24} md={4} lg={4} xl={4}></Col>

            <ButtonField
              value="Add"
              icon="PlusSquareFilled"
              style={{
                background: COLORS.lightGray2,
                color: COLORS.green,
              }}
              onClick={submitHandler}
            ></ButtonField>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}></Col>
          </Row>
        </Form>
      </div>
    </>
  )
}
export default SalesItemForm
