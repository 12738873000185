export default function errorMessageParser(error?: any) {
  var message=""
  if(typeof error=='string' && error!='')
  {   
    message=error   
    return;     
  }
if (error && typeof error.response != 'undefined') {

  var errorsData = error?.response?.data
  if(errorsData.detail)
  {

    message=errorsData.detail  
  }
  else{
    var errors =errorsData?.errors

    var keys = Object.keys(errors)
    let messageStr = ''
    for (var i = 0; i < keys.length; i++) {
      messageStr =
        messageStr + (i + 1).toString() + ' ' + errors[keys[i]] + '<br/>'
    }
    message=messageStr
  }
}
  return message;
}

