import repo from "./repository";
import { FuelTransactionModel ,ParameterModel} from "model";
export default class FuelTransactionRepository {
  async find(id: string): Promise<FuelTransactionModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "FuelTransaction/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,FuelTransactionModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "FuelTransaction", { params: param })
      .then((res) => res?.data);
  }
  async findCGSTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "FuelTransaction/CGSTotal", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "FuelTransaction/" + id)
      .then((res) => res?.data);
  }
  async save(entity: FuelTransactionModel): Promise<FuelTransactionModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "FuelTransaction", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: FuelTransactionModel): Promise<FuelTransactionModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "FuelTransaction/" + id, entity)
      .then((response) => response.data);
  }
}
