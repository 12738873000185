import { useQuery } from "react-query";

import repository from "service";
import { DeliveryModel, ParameterModel } from "model";

const salesRepository = new repository.DeliveryRepository();
let records: DeliveryModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[DeliveryModel[], number]> => {
  await salesRepository.findAllSales(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useSaless(param: ParameterModel) {
  var {
    data: salesData,
    isLoading,
    error,
  } = useQuery(["saless", param], () => fetchData(param), {
   /*enabled:
      (typeof param.searchText != "undefined" && param.searchText != "") ||
      (typeof param.categoryId != "undefined" && param.categoryId != "") ||
      (typeof param.category != "undefined" && param.category != ""),*/
  });
  var data = salesData?.[0];
  var recordCount = salesData?.[1];

  return { data, recordCount, isLoading, error };
}
