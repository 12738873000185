
import axios from 'axios';
import {ParameterModel,NotificationModel} from 'model';


export default class NotificationRepository {
  
  async find(id: string):Promise<NotificationModel> {
    return await axios.get(process.env.REACT_APP_API_URL+'Notification/'+id).then(res => res?.data);
  }
  async findAll(param:ParameterModel): Promise<[number,NotificationModel[]]> {

    return await axios.get(process.env.REACT_APP_API_URL+'Notification', { params:param})
                      .then(res =>res?.data);
  }
  async findAllForResponse(param:ParameterModel): Promise<[number,NotificationModel[]]> {

    return await axios.get(process.env.REACT_APP_API_URL+'Notification/GetForResponse', { params:param})
                      .then(res =>res?.data);
  }
  async count(): Promise<number> {
    return await axios.get(process.env.REACT_APP_API_URL+'GetCount').then(res => res?.data);
  }
  async delete(id:string): Promise<any> {
    return await axios.delete(process.env.REACT_APP_API_URL+'Notification/'+id).then(res => res?.data);
  }
  async save(entity: NotificationModel): Promise<NotificationModel> {
   
    return  await axios
    .post(process.env.REACT_APP_API_URL + "Notification", entity)
    .then(response => response.data);
  }
  async update(id:string,entity: NotificationModel): Promise<NotificationModel> {
    return  await axios
    .put(process.env.REACT_APP_API_URL + "Notification/"+id,entity)
    .then(response => response.data);
  }

  async updateViewStatus(id:string): Promise<NotificationModel> {
    return  await axios
    .put(process.env.REACT_APP_API_URL + "Notification/UpdateViewStatus/"+id)
    .then(response => response.data);
  }
}

