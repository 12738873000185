/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ConnectionStringModel,
  SectionCriteriaModel,
  SectionItemModel,
  SectionModel,
} from "model";

interface initialState {
  connStrings?: ConnectionStringModel[];
  section?: SectionModel;
  sectionItemId?: string;
  sectionItems?: SectionItemModel[];
  sections?: SectionModel[];
  chartType?: string;
  criterias?: SectionCriteriaModel[];
  criteriaType?:string
  criteriaId?: string;
}
const initialState: initialState = {
  connStrings: [] as ConnectionStringModel[],
  sectionItemId: "",
  section: undefined,
  sectionItems: [],
  sections: [],
  chartType: "",
  criteriaType:'',
  criterias: undefined,
  criteriaId: "",
};

const SectionSlice = createSlice({
  name: "Section",
  initialState,
  reducers: {
    setConnStrings(state, { payload }: PayloadAction<[]>) {
      state.connStrings = payload;
    },
    setSection(state, { payload }: PayloadAction<SectionModel>) {
      state.section = payload;
    },
    setCriterias(state, { payload }: PayloadAction<SectionCriteriaModel[]>) {
      state.criterias = payload;
    },
    setCriteriaId(state, { payload }: PayloadAction<string>) {
       state.criteriaId = payload;
    },
    setCriteriaType(state, { payload }: PayloadAction<string>) {
      state.criteriaType = payload;
    },
    setSectionItemId(state, { payload }: PayloadAction<string>) {
      state.sectionItemId = payload;
    },
    setSectionItem(state, { payload }: PayloadAction<SectionItemModel[]>) {
      state.sectionItems = payload;
    },
    setSections(state, { payload }: PayloadAction<SectionModel[]>) {
      state.sections = payload;
    },
    setChartType(state, { payload }: PayloadAction<string>) {
      state.chartType = payload;
    },
  },
});
export const {
  setConnStrings,
  setSection,
  setSectionItem,
  setChartType,
  setSections,
  setSectionItemId,
  setCriteriaId,
  setCriterias,
  setCriteriaType
} = SectionSlice.actions;

export default SectionSlice.reducer;
