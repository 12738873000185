import repo from "./repository";
import { ConnectionStringModel, SectionCriteriaModel ,ParameterModel} from "model";
export default class SectionCriteriaRepository {
  async find(id: string): Promise<SectionCriteriaModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionCriteria/" + id)
      .then((res) => res?.data);
  }

  async getChartData(id: string): Promise<string[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionCriteria/ChartData/"+id)
      .then((res) => res?.data);
  }

  async findAll(param: ParameterModel): Promise<[number,SectionCriteriaModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionCriteria", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "SectionCriteria/" + id)
      .then((res) => res?.data);
  }
  async save(entity: SectionCriteriaModel): Promise<SectionCriteriaModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "SectionCriteria", entity)
      .then((res) => res?.data);
  }
  async update(id: string, entity: SectionCriteriaModel): Promise<SectionCriteriaModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "SectionCriteria/" + id, entity)
      .then((res) =>res?.data);
  }
}
