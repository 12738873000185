import { DeliveryModel } from "model";
import { useMutation, UseMutateFunction } from "react-query";
import repository from "service";

interface updateResult {
  mutate: UseMutateFunction<DeliveryModel, unknown, any, unknown>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: any;
}

const salesRepository = new repository.DeliveryRepository();

const saveSales = async (
  sales: DeliveryModel
): Promise<DeliveryModel> => {
  return await (salesRepository.saveSales(sales));
};

export function useSaveSales(): updateResult {
  const { mutate, isLoading, isError, error, isSuccess } =
    useMutation(saveSales);

  var result: updateResult = { mutate, isLoading, isError, error, isSuccess };
  return result;
}
