/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialState {
  userId?: string;
  source: string;
  language: string;
}
const initialState: initialState = {
  userId: "",
  source: "",
  language: "",
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    set(
      state,
      { payload }: PayloadAction<{ userId?: string; source: string }>
    ) {
      state.userId = payload.userId;
      state.source = payload.source;
    },
    reset(state) {
      state.userId = "";
      state.source = "";
    },
    setLanguage(state, { payload }: PayloadAction<string>) {
      localStorage.setItem("language", JSON.stringify(payload));
      state.language = payload;
    },
    getLanguage(state) {
      const language = localStorage.getItem("language");
      if (language != null) state.language = JSON.parse(language);
      else state.language = "eng";
    },
  },
});

export const { set, reset, setLanguage, getLanguage } = languageSlice.actions;
export default languageSlice.reducer;
