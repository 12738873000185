import repo from "./repository";
import { SectionUserModel ,ParameterModel} from "model";
export default class SectionUserRepository {
  async find(id: string): Promise<SectionUserModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionUser/" + id)
      .then((res) => res?.data);
  }

  async findAll(param: ParameterModel): Promise<[number,SectionUserModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionUser", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "SectionUser/" + id)
      .then((res) => res?.data);
  }
  async save(entity: SectionUserModel): Promise<SectionUserModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "SectionUser", entity)
      .then((res) => res?.data);
  }
  async update(id: string, entity: SectionUserModel): Promise<SectionUserModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "SectionUser/" + id, entity)
      .then((res) => res?.data);
  }
}
