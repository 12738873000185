import repo from "service/repository";
import { ConnectionStringModel, DataSourceObjectFieldModel ,ParameterModel} from "model";
export default class DataSourceObjectFieldRepository {
  async find(id: string): Promise<DataSourceObjectFieldModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSourceObjectField/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,DataSourceObjectFieldModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSourceObjectField", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "DataSourceObjectField/" + id)
      .then((res) => res?.data);
  }
  async save(entity: DataSourceObjectFieldModel[]): Promise<DataSourceObjectFieldModel[]> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "DataSourceObjectField", entity)
      .then((response) => response.data);
  }
  async saveCriteriaField(entity: DataSourceObjectFieldModel[]): Promise<DataSourceObjectFieldModel[]> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "DataSourceObjectField/CriteriaField", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: DataSourceObjectFieldModel): Promise<DataSourceObjectFieldModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "DataSourceObjectField/" + id, entity)
      .then((response) => response.data);
  }
}
