import { Form, DatePicker, DatePickerProps } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

const dateFormat = 'YYYY-MM-DD'
interface Props {
  value: string
  label?: string
  name: string
  isEditable?: boolean
  placeholder?: string
  onChange?: DatePickerProps['onChange']
  isRequired?: boolean
  isInvalid?: boolean
}

const DateField = (props: Props) => {
  const {
    value,
    label,
    name,
    onChange,
    placeholder,
    isRequired,
    isEditable,
  } = props
  const { t } = useTranslation()
  return (
    <Form.Item
      name={name}
      label={t(label as string)}
      style={{ margin: '0 0 10px' }}
      rules={[
        { required: isRequired, message: `Please input ${label as string}!` },
      ]}
    >
      <DatePicker
        size={'large'}
        value={dayjs(value)}
        format={dateFormat}
        onChange={onChange}
        placeholder={t(placeholder as string)}
        name={name}
        disabled={!isEditable}
      ></DatePicker>
    </Form.Item>
  )
}

DateField.defaultProps = {
  value: '',
  isEditable: true,
}

export default DateField
