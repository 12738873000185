import { useContext, useState } from 'react'
import SalesItemList from './salesItemList'
import SalesItemForm from './salesItemForm'
import { Col, Row } from 'antd'
import { SalesContext } from '../sales'
import ButtonField from 'common/ui/ButtonField'
import { COLORS } from 'assets/stlyes/theme'

function SalesItem() {
  const { salesitems,setItemId } = useContext(SalesContext)
  const [showForm, setshowForm] = useState(false)

  return (
    <>
      <div className=" text-md text-md text-slate-500 font-bold"></div>
      <Row>
        <Col
          className=" rounded-lg  border-slate-200 border-dashed border-2 p-2"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <div className=" flex justify-end">
            <div className="mb-2">
              <ButtonField
                value="Add Product"
                icon="PlusCircleFilled"
                style={{ color: COLORS.black,background:COLORS.lightGray2 }}
                onClick={()=>{setshowForm(!showForm);
                  setItemId('')}}
              ></ButtonField>
            </div>
          </div>
          {showForm && <SalesItemForm setShowForm={setshowForm}></SalesItemForm>}
          {!showForm && salesitems.length > 0 && (
            <SalesItemList setShowForm={setshowForm}></SalesItemList>
          )}
        </Col>
        <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}></Col>
      </Row>
    </>
  )
}
export default SalesItem
