import { useQuery } from "react-query";

import repository from "service";
import { TaxTypeModel,ParameterModel } from "model";

const taxTypeRepository = new repository.TaxTypeRepository();
let records: TaxTypeModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[TaxTypeModel[], number]> => {
  await taxTypeRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useTaxTypes(param: ParameterModel) {
  var {
    data: taxTypeData,
    isLoading,
    error,
  } = useQuery(["taxTypes", param], () => fetchData(param), {});
  var data = taxTypeData?.[0];
  var recordCount = taxTypeData?.[1];

  return { data, recordCount, isLoading, error };
}
