import { Spin } from "antd";
import { COLORS } from "assets/stlyes/theme";

function Loading() {
  return (
    <Spin
      size={'large'}
      style={{ position: "absolute", top: "50%", left: "50%", zIndex: 1,color:COLORS.black }}
      className="loading text-slate-600"
    ></Spin>
  );
}

export default Loading;
