import { useCallback, useContext, useState } from 'react'
import { Table } from 'antd'
import { v4 as uuid } from 'uuid'

import type { ColumnsType } from 'antd/lib/table'

import { useSaless } from './hooks/useSaless'
import { parameterInitialData } from 'constants/initialization'
import PaginationPanel from 'common/component/paginationPanel/paginationPanel'
import SearchBar from 'common/component/searchBar/searchBar'
import { useDeleteSales } from './hooks/useDeleteSales'
import { useQueryClient } from 'react-query'
import GridAction from 'common/component/gridAction/gridAction'
import MessageField from 'common/ui/messageField'
import { Link } from 'react-router-dom'
import { useStores } from 'inventory/features/store/hooks/useStores'
import WelcomeBanner from 'dashboard/welcome'
import FloatButton from 'common/component/floatButton/floatButton'
import { SalesContext } from './sales'
import { DeliveryItemModel } from 'model'
import SimpleList from 'common/component/list/simpleList'
import { useUserSelector } from 'state/hook'
import { useSelector } from 'react-redux'
import { usePostReceive } from 'purchase/features/receive/hooks/usePostReceive'
import { useUnPostReceive } from 'purchase/features/receive/hooks/useUnPostReceive'
import errorMessageParser from 'util/errorMessageParser'


function SalesList(props: { editHander: Function }) {
  const { editHander } = props
  const queryClient = useQueryClient()
  const { user } = useSelector(useUserSelector)
  const [param, setParam] = useState({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: storeList } = useStores({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { setShowEdit, setId } = useContext(SalesContext)
  const { data: salesList, isLoading, recordCount } = useSaless(param)
  const { mutate: deleteMutation, isLoading: deleteLoading } = useDeleteSales()
  const { mutate: postMutation, isLoading: postLoading } = usePostReceive()
  const {
    mutate: unPostMutation,
    isLoading: unPostLoading,
  } = useUnPostReceive()
  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <Link className="p-1" to={''} onClick={() => editHander(record.id)}>
        <span
          className={` font-semibold ${record.isPosted && 'text-green-500'}`}
        >
          {text}
        </span>
        <div className="text-[13px] text-slate-400 pl-1">
          {record.customer} {' '}{record.store}{' '}{record.reference}
        </div>
      </Link>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (text, record) => (
        <span className="font-semibold">
           <span
            className={`block ${
              (record.purchaseCategory == 'Credit') 
                ? 'text-red-500' 
                : 'text-green-500'
            }   text-[14px]`}
          >
                {!isNaN(+text + record.tax) ? Number(text + record.tax).toLocaleString() : text + record.tax}
     
            {record.purchaseCategory}
           
          </span>
         
        </span>
      ),
    },
   
  ]
  const detailColumns: ColumnsType<any> = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text, record) => (
        <Link
          className=" text-green-700"
          to={''}
          onClick={() => editHander(record.id)}
        >
          <span className="font-semibold text-slate-700">
            {text} {record.date} {record.reference}
          </span>
          <div className="">
            <span className=" text-slate-600">
              {' '}
              Quantity:{record.quantity} Unit Price:{record.unitPrice}
            </span>
          </div>
        </Link>
      ),
    },

    {
      title: 'Action',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (text, record) => (
        <GridAction
          id={record.id}
          record={record}
          showAdd={false}
          editHandler={editHander}
          deleteHandler={deleteHandler}
          postHandler={postHandler}
          unPostHandler={unPostHandler}
          showPost={record.isPosted ? false : true}
          showUnPost={record.isPosted ? true : false}
          showEdit={false}
        ></GridAction>
      ),
    },
  ]
  const calculateTotal = (items: any[], rate: number) => {
    if (typeof items != 'undefined' && items.length > 0) {
      var total = items.reduce((prev, item) => {
        return prev + (item.unitPrice as number) * (item.quantity as number)
      }, 0)
      var summary =
        'SubTotal=' +
        total.toLocaleString() +
        ' Tax=' +
        (total * (rate as number)).toLocaleString() +
        ' Total=' +
        (total + total * (rate as number)).toLocaleString()
      return summary
    }
  }
  const searchTextChangeHandler = (e: any) => {
    const { name, value } = e.target
    setParam((prevState) => ({
      ...prevState,
      searchText: value,
      offset: 0,
      limit: param.limit,
    }))
  }
  const searchHandler = () => {}
  const setPagination = (valObj: any) => {
    setParam((prevState) => ({
      ...prevState,
      offset: valObj.min,
      limit: valObj.max,
    }))
  }

  const deleteHandler = useCallback((id: string) => {
    deleteMutation(id, {
      onSuccess: (data, variables, context) => {
        MessageField({ type: 'success' })
        queryClient.invalidateQueries('deliverys')
      },
      onError: (error, variables, context) => {
        var message = errorMessageParser(error)
        MessageField({ type: 'error', content: message as string })
      },
    })
  }, [])
  const postHandler = useCallback((id: string) => {
    postMutation(id, {
      onSuccess: (data, variables, context) => {
        MessageField({ type: 'success' })
        queryClient.invalidateQueries('saless')
      },
      onError: (error, variables, context) => {
        var message = errorMessageParser(error)
        MessageField({ type: 'error', content: message as string })
      },
    })
  }, [])
  const unPostHandler = useCallback((id: string) => {
    unPostMutation(id, {
      onSuccess: (data, variables, context) => {
        MessageField({ type: 'success' })
        queryClient.invalidateQueries('saless')
      },
      onError: (error, variables, context) => {
        var message = errorMessageParser(error)
        // alert(message)
        //  MessageField({ type: 'success' })
        MessageField({ type: 'error', content: message as string })
      },
    })
  }, [])
  return (
    <>
      <SearchBar
        param={param}
        setParam={setParam}
        categoryData={[{ id: 'Cash', name: 'Credit' }]}
        storeData={storeList}
        submitHandler={searchHandler}
        textChangeHnadler={searchTextChangeHandler}
      ></SearchBar>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={salesList}
        loading={isLoading || deleteLoading}
        expandable={{
          expandedRowRender: (record) => (
            <SimpleList
              key={uuid()}
              loading={isLoading}
              summary={calculateTotal(record.items, record.taxRate) as string}
              deleteHandler={deleteHandler}
              dataSource={
                record?.items?.map((item: DeliveryItemModel) => {
                  return {
                    id: item.id,
                    name: (
                      <span className="">
                        {item.product}
                        <span className="text-slate-400 text-sm"></span>
                      </span>
                    ),
                    total: (
                      (item.unitPrice as number) * (item.quantity as number)
                    ).toLocaleString(),
                    description: (
                      <>
                        <div className="text-xs ">
                          <span className="  text-slate-400">Quantity:</span>
                          <span className="  text-slate-700 font-bold">
                            {item.quantity}
                          </span>{' '}
                          <span className="  text-slate-400">Price:</span>
                          <span className="  text-slate-700 font-bold">
                            {item.unitPrice}
                            <span className=" text-slate-600"></span>
                          </span>
                        </div>
                      </>
                    ),
                  }
                }) as never
              }
              defaultPageSize={0}
            ></SimpleList>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        pagination={false}
      />
      <PaginationPanel
        total={recordCount as number}
        defaultPageSize={15}
        setPaginationHandler={setPagination}
      ></PaginationPanel>
      <FloatButton
        openForm={() => {
          setShowEdit(true)
          setId('')
        }}
      ></FloatButton>
    </>
  )
}
export default SalesList
