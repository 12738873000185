import { useQuery } from "react-query";

import repository from "service";
import { CarWashModel,ParameterModel } from "model";

const carWashRepository = new repository.CarWashRepository();
let records: CarWashModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[CarWashModel[], number]> => {
  await carWashRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useCarWashs(param: ParameterModel) {
  var {
    data: carWashData,
    isLoading,
    error,
  } = useQuery(["carWashs", param], () => fetchData(param), {});
  var data = carWashData?.[0];
  var recordCount = carWashData?.[1];

  return { data, recordCount, isLoading, error };
}
