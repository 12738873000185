import repo from "./repository";
import { MeterReadingModel ,ParameterModel} from "model";
export default class MeterReadingRepository {
  async find(id: string): Promise<MeterReadingModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "MeterReading/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,MeterReadingModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "MeterReading", { params: param })
      .then((res) => res?.data);
  }
  async findSalesTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "MeterReading/SalesTotal", { params: param })
      .then((res) => res?.data);
  }
  async findDailySalesTotal(
    tankId: string,
    date: string
  ): Promise<number> {
    const queryParams = new URLSearchParams();
    queryParams.append("tankId", tankId);
    queryParams.append("date", date);
    return await repo
      .get(
        process.env.REACT_APP_API_URL +
          "MeterReading/DialySales?" +
          queryParams.toString()
      )
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "MeterReading/" + id)
      .then((res) => res?.data);
  }
  async save(entity: MeterReadingModel): Promise<MeterReadingModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "MeterReading", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: MeterReadingModel): Promise<MeterReadingModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "MeterReading/" + id, entity)
      .then((response) => response.data);
  }
}
