import { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Row, Col, DatePickerProps } from 'antd'
import { useSelector } from 'react-redux'

import { SalesContext } from './sales'
import { useSaveSales } from './hooks/useSaveSales'
import { useUserSelector } from 'state/hook'
import { DefaultModel, DeliveryModel } from 'model'
import MessageField from 'common/ui/messageField'
import StatusBar from 'common/component/statusBar/statusBar'
import ActionBar from 'common/component/actionBar/actionBar'
import SelectField from 'common/ui/selectField'
import { parameterInitialData } from 'constants/index'
import { useQueryClient } from 'react-query'
import { useStores } from 'inventory/features/store/hooks/useStores'
import formateDate from 'util/dateFormater'

import dayjs, { Dayjs } from 'dayjs'
import DateField from 'common/ui/dateField'

import { useTaxTypes } from 'vat/features/taxType/hooks/useTaxTypes'
import SalesItem from './details'
import useSales from './hooks/useSales'
import SalesDetail from './paymentDetail'
import { useUsers } from 'Admin/features/user/hooks/useUsers'
import { useUsersByCompany } from 'Admin/features/user/hooks/useUsersByCompany'
const dateFormat = 'YYYY-MM-DD'

function SalesForm() {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const { user } = useSelector(useUserSelector)
  const { data: storeList } = useStores({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: taxTypeList } = useTaxTypes({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: userList } = useUsersByCompany({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const initialState = {
    companyId: user?.companyId,
    storeId: storeList?.filter((a) => a.isDefault)?.[0]?.id,
    store: storeList?.filter((a) => a.isDefault)?.[0]?.name,
    taxType: taxTypeList?.filter((a) => a.isDefault)?.[0]?.name as string,
    taxRate: taxTypeList?.filter((a) => a.isDefault)?.[0]?.rate,

    createdBy: user?.firstName + '' + user?.lastName,
    updatedBy: user?.firstName + '' + user?.lastName,
    cashierId: user?.id,
    cashierName: user?.firstName + '' + user?.lastName,
  } as DeliveryModel

  const [formData, setFormtData] = useState(initialState)
  const [totalSummary, setTotalSummary] = useState('')
  const {
    salesId,
    salesItemId,
    salesitems,
    transactionDetails,
    setTransactionDetails,
    setItems,
    setItemId,
  } = useContext(SalesContext)
  const { data: salesData, isLoading: salesLoading } = useSales(salesId)

  const {
    mutate: saveMutation,
    isLoading: saveLoading,
    error,
    isError,
    isSuccess,
  } = useSaveSales()

  useEffect(() => {
    if (typeof salesData != 'undefined') {
      setFormtData({
        ...salesData,
        date: formateDate(salesData.date),
      })
      form.setFieldsValue({
        ...salesData,
        date: dayjs(salesData.date),
      })
      setItems(salesData.items as never)
      setTransactionDetails(salesData.details as never)
    } else {
      resetHandler()
    }
  }, [salesData])

  useEffect(() => {
    if (typeof taxTypeList != 'undefined' && taxTypeList.length > 0) {
      setFormtData((prevState) => ({
        ...prevState,
        taxType: taxTypeList.filter((a) => a.isDefault)?.[0]?.name as string,
        taxRate: taxTypeList.filter((a) => a.isDefault)?.[0]?.rate,
      }))
      form.setFieldsValue({
        taxType: taxTypeList.filter((a) => a.isDefault)?.[0]?.name,
      })
    }
  }, [taxTypeList])

  useEffect(() => {
    if (typeof storeList != 'undefined' && storeList.length > 0) {
      setFormtData((prevState) => ({
        ...prevState,
        storeId: storeList.filter((a) => a.isDefault)?.[0]?.id,
        store: storeList.filter((a) => a.isDefault)?.[0]?.name,
      }))
      form.setFieldsValue({
        store: storeList.filter((a) => a.isDefault)?.[0]?.name,
      })
    }
  }, [storeList])

  useEffect(() => {
    calculateTotal()
  }, [salesitems])
  const calculateTotal = () => {
    if (typeof salesitems != 'undefined' && salesitems.length > 0) {
      var total = salesitems.reduce((prev, item) => {
        return prev + (item.unitPrice as number) * (item.quantity as number)
      }, 0)
      setFormtData((prevState) => ({
        ...prevState,
        amount: total,
        tax: total * (prevState.taxRate as number),
      }))
      setTotalSummary(
        'SubTotal=' +
          total +
          ' Tax=' +
          total * (formData.taxRate as number) +
          ' Total=' +
          (total + total * (formData.taxRate as number)),
      )
    } else {
      setTotalSummary('')
      setFormtData((prevState) => ({
        ...prevState,
        amount: 0,
        tax: 0,
      }))
    }
  }
  const changeHandler = useCallback((e: any) => {
    const { name, value } = e.target
    setFormtData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])
  const selectStoreHandler = useCallback((value: any) => {
    setFormtData((prevState) => ({
      ...prevState,
      storeId: value,
    }))
  }, [])

  const selectTaxTypeHandler = useCallback(
    (value: any) => {
      var record = taxTypeList?.filter((a) => a.name == value)?.[0]
      setFormtData((prevState) => ({
        ...prevState,
        taxType: value,
        taxRate: record?.rate,
      }))
      calculateTotal()
    },
    [taxTypeList],
  )
  const dateChangeHander: DatePickerProps['onChange'] = useCallback(
    (date: any, dateString: string) => {
      setFormtData((prevState) => ({
        ...prevState,
        date: dateString,
      }))
    },
    [],
  )
  const selectUserHandler = useCallback(
    (value: any) => {
      var record = userList?.filter((a) => a.id == value)[0]
      setFormtData((prevState) => ({
        ...prevState,
        cashierId: value,
        cashierName: record?.firstName + ' ' + record?.lastName,
      }))
    },
    [userList],
  )
  const resetHandler = useCallback(() => {
    setFormtData({
      ...initialState,
      date: formateDate(new Date().toDateString()),
    })
    form.resetFields()
    form.setFieldsValue({
      ...initialState,
      date: dayjs(new Date().toLocaleString()),
    })
    setItems([])
    setItemId('')
    setTransactionDetails([])
  }, [])
  const save = async () => {
    saveMutation(
      { ...formData, items: salesitems, details: transactionDetails },

      {
        onSuccess: (data, variables, context) => {
          resetHandler()
          MessageField({ type: 'success' })
          queryClient.invalidateQueries('saless')
        },
      },
    )
  }
  const submitHandler = async () => {
    console.log(formData.date)
    await form.validateFields().then(() => {
      {
        save()
      }
    })
  }
  return (
    <>
      <div style={{ height: 0 }}></div>
      <div className="formPanel">
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          autoComplete="off"
        >
          <Row>
            <Col className="p-0 m-0" xs={24} sm={24} md={4} lg={4} xl={4}></Col>
            <StatusBar
              errorMessage={error}
              isLoading={saveLoading || salesLoading}
              isError={isError}
              successMessage={isSuccess ? '' : ''}
            ></StatusBar>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}></Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <SelectField
                name="store"
                label="Branch"
                placeholder="Branch"
                value={formData.store}
                onChange={selectStoreHandler}
                isRequired={true}
                dataOptions={
                  storeList?.map((item) => {
                    return { id: item.id, name: item.name }
                  }) as never
                }
              />

              <Form.Item style={{ margin: '0 0 10px' }}>
                <Row>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>

                  <Col xs={11} sm={11} md={9} lg={9} xl={9}>
                    <DateField
                      name="date"
                      label="Date"
                      value={formData.date}
                      onChange={dateChangeHander}
                      isRequired={true}
                    />
                  </Col>
                  <Col xs={2} sm={2} md={1} lg={1} xl={1}></Col>

                  <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                    <SelectField
                      name="taxType"
                      label="Tax Type"
                      placeholder="Tax Type"
                      value={formData.taxType}
                      onChange={selectTaxTypeHandler}
                      isRequired={true}
                      dataOptions={
                        taxTypeList?.map((item) => {
                          return {
                            id: item.name,
                            name: item.name,
                            description: item.rate,
                          }
                        }) as never
                      }
                    />
                  </Col>
                </Row>
              </Form.Item>
              <SelectField
                name="cashierName"
                label="Cashier"
                placeholder="Cashier"
                value={formData.cashierName}
                onChange={selectUserHandler}
                isRequired={true}
                dataOptions={
                  userList?.map((item) => {
                    return {
                      id: item.id,
                      name: item.firstName + ' ' + item.lastName,
                    }
                  }) as never
                }
              />
              <SalesDetail></SalesDetail>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <SalesItem></SalesItem>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>

          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            {
              <ActionBar
                showDelete={false}
                showClear={false}
                onrest={resetHandler}
                onSave={submitHandler}
                disableAdd={
                  typeof salesitems != 'undefined' &&
                  salesitems.length > 0
                    ? false
                    : true
                }
              />
            }
            <div className=" italic font-semiBold text-slate-700 text-end">
              {totalSummary}
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default SalesForm
