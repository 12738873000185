import { useEffect, useState } from 'react'
import { Form, Table } from 'antd'
import { useSelector } from 'react-redux'

import { useUserSelector } from 'state/hook'
import { parameterInitialData } from 'constants/index'
import { ColumnsType } from 'antd/es/table'
import { useStores } from 'inventory/features/store/hooks/useStores'
import { useMachines } from 'vat/features/machine/hooks/useMachines'
import SearchBar from 'common/component/searchBar/searchBar'
import { useTins } from 'vat/features/tin/hooks/useTins'
import { useMeterReadings } from 'gasStation/features/meterReading/hooks/useMeterReadings'
import { useSaless } from 'Sales/features/sales/hooks/useSaless'
import { useSalesOrders } from 'Sales/features/SalesOrder/hooks/useSalesOrders'
import { useCarWashs } from 'gasStation/features/carWash/hooks/useCarWashs'
import { useParkings } from 'gasStation/features/parking/hooks/useParkings'
import { useExpenses } from 'vat/features/expense/hooks/useExpenses'

function DayBookReport() {
  const [form] = Form.useForm()
  const { user } = useSelector(useUserSelector)
  const [totalDayBook, setTotalDayBook] = useState(0)
  const [detailSales, setDetailSales] = useState([])
  const [creditDetailSales, setCreditDetailSales] = useState([])
  const [param, setParam] = useState({
    ...parameterInitialData,
    companyId: user?.companyId,
  })

  const { data: machineList, isLoading: machineLoading } = useMachines(param)
  const { data: storeList } = useStores({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: tinList } = useTins({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: otherSalesList, isLoading: otherSalesLoading } = useSaless({
    ...param,

  })
  const {
    data: creditSalesList,
    isLoading: creditSalesLoading,
  } = useSalesOrders({ ...param, category: 'Credit' })
  const { data: fuelSalesList, isLoading: fuelSalesLoading } = useMeterReadings(
    param,
  )

  const { data: carWashList, isLoading: carWashLoading } = useCarWashs(param)
  const { data: parkingList, isLoading: parkingLoading } = useParkings(param)

  const {
    data: expenseList,
    isLoading: expenseLoading,
  } = useExpenses({ ...param })
  useEffect(() => {
    var results = otherSalesList?.reduce((result, record) => {
      record?.items?.map((item: any) =>
        result.push({
          ...item,
          total: item.unitPrice * item.quantity,
        } as never),
      )
      return result
    }, [])

    setDetailSales(results as never)
  }, [otherSalesList])
  useEffect(() => {
    var results = creditSalesList?.reduce((result, record) => {
      record?.items?.map((item: any) =>
        result.push({
          ...item,
          total: item.unitPrice * item.quantity,
          ...record,
        } as never),
      )
      return result
    }, [])

    setCreditDetailSales(results as never)
  }, [creditSalesList])

  useEffect(() => {
    var totalFuelSalesDayBook = fuelSalesList?.reduce(
      (sum, a: any) => sum + ((a.totalSales) as number),
      0,
    )
    var totalSalesDayBook = detailSales?.reduce(
      (sum, a: any) => sum + a.total,
      0,
    )
    var totalcarWashListBook = carWashList?.reduce(
      (sum, a) => sum + (a.total as number),
      0,
    )
    var totalparkingListBook = parkingList?.reduce(
      (sum, a) => sum + (a.total as number),
      0,
    )
    var creditSaleseDayBook = creditSalesList?.reduce(
      (sum, a: any) => sum + ((a.amount) as number),
      0,
    )
    var totalExpenseDayBook = expenseList?.reduce(
      (sum, a) => sum + (a.amount as number),
      0,
    )
    var net =
      (typeof totalFuelSalesDayBook == 'undefined'
        ? 0
        : totalFuelSalesDayBook) +
      (typeof totalSalesDayBook == 'undefined' ? 0 : totalSalesDayBook) +
      (typeof totalcarWashListBook == 'undefined' ? 0 : totalcarWashListBook) +
      (typeof totalparkingListBook == 'undefined' ? 0 : totalparkingListBook) -
      (typeof totalExpenseDayBook == 'undefined' ? 0 : totalExpenseDayBook)
      -
      (typeof creditSaleseDayBook == 'undefined' ? 0 : creditSaleseDayBook)
    setTotalDayBook(net)
  }, [
    expenseList,
    fuelSalesList,
    otherSalesList,
    detailSales,
    carWashList,
    parkingList,
    creditSalesList,
  ])

  const otherSalesColumns: ColumnsType<any> = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text, record) => (
        <>
          <span className=" ">{text}</span>
          <div className=" text-xs">
            <span className=" text-slate-500">{record.store}</span>
          </div>
        </>
      ),
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
      render: (text, record) => (
        <>
          <span className=" ">{record.quantity * record.unitPrice}</span>
        </>
      ),
    },
  ]
  const creditSalesColumns: ColumnsType<any> = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text, record) => (
        <>
          <span className=" ">{text}</span>
          <div className=" text-xs">
            <span className=" text-slate-500">{record.store}</span>
          </div>
        </>
      ),
    },

    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
     },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
      render: (text, record) => (
        <>
          <span className=" ">{record.quantity * record.unitPrice}</span>
        </>
      ),
    },
  ]
  const carWashColumns: ColumnsType<any> = [
    {
      title: 'Cashier',
      dataIndex: 'cashierName',
      key: 'cashierName',
      render: (text, record) => (
        <>
          <span className=" ">{text}</span>
          <div className=" text-xs">
            <span className=" text-slate-500">{record.reference}</span>
          </div>
        </>
      ),
    },

    {
      title: 'Car Washier',
      dataIndex: 'carWasherNames',
      key: 'carWasherNames',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
     
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
     
    },

    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
     
      render: (text, record) => (
        <>
          <span className=" ">{record.total}</span>
        </>
      ),
    },
  ]
  const parkingColumns: ColumnsType<any> = [
    {
      title: 'Cashier',
      dataIndex: 'cashierName',
      key: 'cashierName',
      render: (text, record) => (
        <>
          <span className=" ">{text}</span>
          <div className=" text-xs">
            <span className=" text-slate-500">{record.reference}</span>
          </div>
        </>
      ),
    },

    {
      title: 'Cars',
      dataIndex: 'cars',
      key: 'cars',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
     
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
     
    },

    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
     
      render: (text, record) => (
        <>
          <span className=" ">{record.total}</span>
        </>
      ),
    },
  ]
  const expenseColumns: ColumnsType<any> = [
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      key: 'expenseType',
      width: 450,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
     
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
     
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 200,
    },
   
  ]
  const fuleSalesColumns: ColumnsType<any> = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text, record) => (
        <>
          <span className=" ">
            {record.dispenser}({text})
          </span>
          <div className=" text-xs">
            <span className=" text-slate-500">
              {record.store}({record.cashier})
            </span>
          </div>
        </>
      ),
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
     
    },
    {
      title: 'Open Reading',
      dataIndex: 'openReading',
      key: 'openReading',
     
    },
    {
      title: 'Closing Reading',
      dataIndex: 'closeReading',
      key: 'closeReading',
     
    },
   
    {
      title: 'Quantity',
      dataIndex: 'salesQuantity',
      key: 'salesQuantity',
     
    },
    {
      title: 'None Standard Sales',
      dataIndex: 'noneStandardTotal',
      key: 'noneStandardTotal',
     
      render: (text, record) => (
        <>
          <span className=" ">
            {(record.noneStandardTotal).toLocaleString()}
          </span>
        </>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalSales',
      key: 'totalSales',
     
      render: (text, record) => (
        <>
          <span className=" ">
            {(record.totalSales).toLocaleString()}
          </span>
        </>
      ),
    },
  ]
  const searchHandler = () => {}
  const searchTextChangeHandler = (e: any) => {
    const { name, value } = e.target
    setParam((prevState) => ({
      ...prevState,
      searchText: value,
    }))
  }

  return (
    <div className="p-4 bg-slate-100">
      <div style={{ height: 0 }}></div>
      <SearchBar
        param={param}
        setParam={setParam}
        categoryData={machineList}
        groupData={tinList}
        storeData={storeList}
        submitHandler={searchHandler}
        textChangeHnadler={searchTextChangeHandler}
      ></SearchBar>
      <div className="font-bold m-2 text-lg ">Fuel Sales</div>
      <Table
        columns={fuleSalesColumns}
        rowKey="id"
        dataSource={fuelSalesList}
        loading={fuelSalesLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
            
              <Table.Summary.Cell index={4}>
                {' '}
                {Number(
                  fuelSalesList?.reduce(
                    (sum, a: any) => sum + (a.salesQuantity as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {' '}
                {Number(
                  fuelSalesList?.reduce(
                    (sum, a: any) => sum + (a.NoneStandardTotal as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} className="font-bold">
                {Number(
                  fuelSalesList?.reduce(
                    (sum, a: any) =>
                      sum + ((a.totalSales) as number),

                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <div className="font-bold m-2 text-lg ">Lubricant and other Sales</div>
      <Table
        columns={otherSalesColumns}
        rowKey="id"
        dataSource={detailSales}
        loading={otherSalesLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>

              <Table.Summary.Cell index={2}>
                {Number(
                  detailSales?.reduce(
                    (sum, a: any) => sum + (a.quantity as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>

              <Table.Summary.Cell index={4} className="font-bold">
                {Number(
                  detailSales?.reduce(
                    (sum, a: any) => sum + (a.total as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div className="font-bold m-2 text-lg ">Credit Sales</div>
      <Table
        columns={creditSalesColumns}
        rowKey="id"
        dataSource={creditDetailSales}
        loading={creditSalesLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>

              <Table.Summary.Cell index={4}>
                {Number(
                  creditDetailSales?.reduce(
                    (sum, a: any) => sum + (a.quantity as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>

              <Table.Summary.Cell index={6} className="font-bold">
                {Number(
                  creditDetailSales?.reduce(
                    (sum, a: any) => sum + (a.total as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div className="font-bold m-2 text-lg ">Car Wash</div>
      <Table
        columns={carWashColumns}
        rowKey="id"
        dataSource={carWashList}
        loading={carWashLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {Number(
                  carWashList?.reduce(
                    (sum, a: any) => sum + (a.quantity as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>

              <Table.Summary.Cell index={6} className="font-bold">
                {Number(
                  carWashList?.reduce(
                    (sum, a: any) => sum + (a.total as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <div className="font-bold m-2 text-lg ">Parking</div>
      <Table
        columns={parkingColumns}
        rowKey="id"
        dataSource={parkingList}
        loading={parkingLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {Number(
                  parkingList?.reduce(
                    (sum, a: any) => sum + (a.quantity as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>

              <Table.Summary.Cell index={6} className="font-bold">
                {Number(
                  parkingList?.reduce(
                    (sum, a: any) => sum + (a.total as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <div className="font-bold m-2 text-lg ">Expense</div>
      <Table
        columns={expenseColumns}
        rowKey="id"
        dataSource={expenseList}
        loading={expenseLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
           
              <Table.Summary.Cell index={6} className="font-bold">
                {Number(
                  expenseList?.reduce(
                    (sum, a: any) => sum + (a.amount as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
     
      />


<Table
        columns={ [
          {
            title: 'Summary',
            dataIndex: 'summary',
            key: 'summary',
        
          }]}
        rowKey="id"
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
           

           <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
           
              <Table.Summary.Cell index={2}>
                Fuel Sales Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="font-bold">
                {Number(
                  fuelSalesList?.reduce(
                    (sum, a) =>
                      sum + (a.totalSales as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                Total Other Sales
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="font-bold">
                {Number(
                  detailSales?.reduce((sum, a: any) => sum + a.total, 0),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                Total Car Wash Sales
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="font-bold">
                {Number(
                  carWashList?.reduce((sum, a: any) => sum + a.total, 0),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                Total Parking Sales
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="font-bold">
                {Number(
                  parkingList?.reduce((sum, a: any) => sum + a.total, 0),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
           
              <Table.Summary.Cell index={2}>
                Total Expense
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="font-bold">
              {'-'}{' '} {Number(
                  expenseList?.reduce(
                    (sum, a) =>
                      sum + (a.amount as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>Credit Sales</Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="font-bold">
                {'-'}{' '}
                {Number(
                  creditSalesList?.reduce((sum, a: any) => sum + a.amount, 0),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>Net DayBook</Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="font-bold">
                {Number(totalDayBook).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

    </div>
  )
}
export default DayBookReport
