import { createContext, useState } from 'react'
import { Row, Col } from 'antd'

import BankTransactionList from './bankTransactionList'
import BankTransactionForm from './bankTransactionForm'
import FormNavBar from 'common/component/formNavBar/formNav'
import { VoucherTransactionModel } from 'model'

export const BankTransactionContext = createContext({
  bankTransactionId: '',
  setId: (val: string) => {},
  setShowEdit: (val: boolean) => {},
  transactionDetailId: '',
  transactionType: '',
  setTransactionType: (val: string) => {},
  transactionDetails: [] as VoucherTransactionModel[],
  setTransactionDetailId: (val: string) => {},
  setTransactionDetails: (val: VoucherTransactionModel[]) => {},
})
function BankTransaction() {
  const [showForm, setshowForm] = useState(false)
  const [bankTransactionId, setBankTransactionId] = useState('')
  const [transactionDetailId, setTransactionDetailId] = useState('')
  const [transactionType, setTransactionType] = useState('')
  const [transactionDetails, setTransactionDetails] = useState(
    [] as VoucherTransactionModel[],
  )

  const [showEdit, setshowEdit] = useState(false)
  const onAdd = () => {
    setId('')
    setshowForm(true)
  }
  const onEdit = (id: string) => {
    setId(id)
    setshowForm(true)
  }
  const onList = () => setshowForm(false)
  const setId = (val: string) => setBankTransactionId(val)
  const setShowEdit = (val: boolean) => {
    setId('')
    setshowForm(val)
  }

  return (
    <div>
      <BankTransactionContext.Provider
        value={{
          bankTransactionId,
          transactionDetailId,
          setTransactionDetailId,
          transactionType,
          setTransactionType,
          transactionDetails,
          setTransactionDetails,
          setId,
          setShowEdit,
        }}
      >
        <FormNavBar
          title={'Bank Transaction'}
          showForm={showForm}
          hideAdd={true}
          onAdd={onAdd}
          onList={onList}
        />
        <Row>
          <Col
            className="content"
            span={24}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            {showForm == false && (
              <BankTransactionList editHander={onEdit}></BankTransactionList>
            )}{' '}
            {showForm == true && <BankTransactionForm></BankTransactionForm>}
          </Col>
        </Row>
      </BankTransactionContext.Provider>
    </div>
  )
}
export default BankTransaction
