import { combineReducers } from "redux";
import user from "./userReducer";
import dataSource from "./dataSourceReducer";
import language from "./languageReducer";
import menu from "./menuReducer";
import product from "./productReducer";
import section from "./sectionReducer";


const rootReducer = combineReducers({
    user,
    dataSource,
    language,
    menu,
    product,
    section
  })

export default rootReducer
  