import { useQuery } from "react-query";

import repository from "service";
import { MeterReadingModel, ParameterModel } from "model";

const meterReadingRepository = new repository.MeterReadingRepository();
let records: MeterReadingModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[MeterReadingModel[], number]> => {
  await meterReadingRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useMeterReadings(param: ParameterModel) {
  var {
    data: meterReadingData,
    isLoading,
    error,
  } = useQuery(["meterReadings", param], () => fetchData(param), {
   /*enabled:
      (typeof param.searchText != "undefined" && param.searchText != "") ||
      (typeof param.categoryId != "undefined" && param.categoryId != "") ||
      (typeof param.category != "undefined" && param.category != ""),*/
  });
  var data = meterReadingData?.[0];
  var recordCount = meterReadingData?.[1];

  return { data, recordCount, isLoading, error };
}
