import repo from "./repository";
import { AdjustmentModel ,ParameterModel} from "model";
export default class AdjustmentRepository {
  async find(id: string): Promise<AdjustmentModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Adjustment/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,AdjustmentModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Adjustment", { params: param })
      .then((res) => res?.data);
  }
  async findCGSTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Adjustment/CGSTotal", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Adjustment/" + id)
      .then((res) => res?.data);
  }
  async save(entity: AdjustmentModel): Promise<AdjustmentModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Adjustment", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: AdjustmentModel): Promise<AdjustmentModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Adjustment/" + id, entity)
      .then((response) => response.data);
  }
}
