import repo from "./repository";
import { SalesOrderModel ,ParameterModel, VatReportModel, SalesOrderItemModel} from "model";
export default class SalesOrderRepository {
  async find(id: string): Promise<SalesOrderModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SalesOrder/" + id)
      .then((res) => res?.data);
  }
  async findItems(id: string): Promise<SalesOrderItemModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SalesOrder/Items/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,SalesOrderModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SalesOrder", { params: param })
      .then((res) => res?.data);
  }
  async findAllRemaining(param: ParameterModel): Promise<[number,SalesOrderModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SalesOrder/Remaining", { params: param })
      .then((res) => res?.data);
  }
  async findAllVatReport(param: VatReportModel): Promise<SalesOrderModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SalesOrder/VatReport", { params: param })
      .then((res) => res?.data);
  }
  
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "SalesOrder/" + id)
      .then((res) => res?.data);
  }
  async save(entity: SalesOrderModel): Promise<SalesOrderModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "SalesOrder", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: SalesOrderModel): Promise<SalesOrderModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "SalesOrder/" + id, entity)
      .then((response) => response.data);
  }
  async post(id: string): Promise<SalesOrderModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "SalesOrder/Post/"+id)
      .then((response) => response.data);
  }
  async unPost(id: string): Promise<SalesOrderModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "SalesOrder/UnPost/"+id)
      .then((response) => response.data);
  }
}
