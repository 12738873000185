import { useState, useRef, useEffect } from 'react'
import Icon from '../icons/customIcon'


function FloatButton(props: { openForm?: Function }) {
  const { openForm } = props

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const trigger = useRef(null)
  const dropdown = useRef(null)

  return (
    <div className="relative inline-flex ml-3">
      <button
        ref={trigger}
        className={`fixed z-50  bottom-20 right-7 p-[15px] pb-[14.5px] rounded-full bg-green-600 hover:bg-green-600 shadow-md hover:shadow-lg text-white '
        }`}
        aria-haspopup="true"
        onClick={() => {
          setDropdownOpen(!dropdownOpen)
          openForm?.(true)
        }}
        aria-expanded={dropdownOpen}
      >
        <span className='p-[1px]'>
          <Icon icon={'PlusOutlined'}></Icon>
        </span>
      </button>
    </div>
  )
}

export default FloatButton
