import repo from "./repository";
import { AccountMappingModel ,ParameterModel} from "model";
export default class AccountMappingRepository {
  async find(id: string): Promise<AccountMappingModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "AccountMapping/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,AccountMappingModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "AccountMapping", { params: param })
      .then((res) => res?.data);
  }
  async findAllSource(
    accountType: string,
    compnyId: string
  ): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("accountType", accountType);
    queryParams.append("companyId", compnyId);
    return await repo
      .get(
        process.env.REACT_APP_API_URL +
          "AccountMapping/Source?" +
          queryParams.toString()
      )
      .then((res) => res?.data);
  }
  async findCGSTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "AccountMapping/CGSTotal", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "AccountMapping/" + id)
      .then((res) => res?.data);
  }
  async save(entity: AccountMappingModel): Promise<AccountMappingModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "AccountMapping", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: AccountMappingModel): Promise<AccountMappingModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "AccountMapping/" + id, entity)
      .then((response) => response.data);
  }
}
