import axios from "axios";


class Repository {
  async get(url: string, data?: any) {
  

    try {
      return await axios.get(url, {
        ...data,
        headers: {
          authorization: `Bearer ${this.getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      
    } catch (e:any) {
      if(e?.response.status=='401')
      {
        throw e
      }
    }
  }
  async delete(url: string) {
    return await axios.delete(url, {
      headers: {
        authorization: `Bearer ${this.getToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
  async post(url: string, data: any) {
    return await axios.post(url, data, {
      headers: {
        authorization: `Bearer ${this.getToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
  async put(url: string, data?: any) {
    return await axios.put(url, data, {
      headers: {
        authorization: `Bearer ${this.getToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
  getToken() {
    const user =
      typeof localStorage.getItem("user") != "undefined" &&
      localStorage.getItem("user") != null &&
      localStorage.getItem("user") != ''
        ? JSON.parse(localStorage.getItem("user") as string)
        : null;
    let token = "";
    if (user != null) {
      token = user.accessToken;
    }
     return token;
  }
}


var repo = new Repository();
export default repo;
