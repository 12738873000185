import { useQuery } from "react-query";

import repository from "service";
import { DeliveryModel } from "model";

const DeliveryRepository = new repository.DeliveryRepository();

async function getDeliveryById(deliveryId: string): Promise<DeliveryModel> {
  return await DeliveryRepository.find(deliveryId);
}
export default function useSales(deliveryId: string) {
  return useQuery(["sales", deliveryId], () => getDeliveryById(deliveryId), {
    enabled: typeof deliveryId != "undefined" && deliveryId != "",
  });
}
