import repo from "./repository";
import { DipReadingModel ,ParameterModel} from "model";
export default class DipReadingRepository {
  async find(id: string): Promise<DipReadingModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DipReading/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,DipReadingModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DipReading", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "DipReading/" + id)
      .then((res) => res?.data);
  }
  async save(entity: DipReadingModel): Promise<DipReadingModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "DipReading", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: DipReadingModel): Promise<DipReadingModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "DipReading/" + id, entity)
      .then((response) => response.data);
  }
}
