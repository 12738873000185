import { useState, useRef, useEffect, EventHandler, useMemo, memo } from 'react'

import Transition from 'util/Transition'
import Icon from '../icons/customIcon'
import SelectField from 'common/ui/selectField'
import ButtonField from 'common/ui/ButtonField'
import { Col, Form, Row } from 'antd'
import { COLORS } from 'assets/stlyes/theme'
import { ParameterModel } from 'model'
import InputField from 'common/ui/inputField'
import DateField from 'common/ui/dateField'
import { Dayjs } from 'dayjs'




  const Filter = memo(function (props: {
  submitHandler?: Function
   param?: ParameterModel
  setParam?: Function
  total?: number
  grandTotal?: number
  categoryData?: any[]
  groupData?: any[]
  storeData?: any[]
  changeHandler?: EventHandler<any>
  fromDateChangeHander?:(value: Dayjs | null, dateString: string) => void
  toDateChangeHander?:(value: Dayjs | null, dateString: string) => void
  selectCategoryHandler?:EventHandler<any>
  selectGroupHandler?:EventHandler<any>
  selectStoreHandler?:EventHandler<any>
  resetHandler?:Function
}) {
  const {
    param,
    setParam,
    categoryData,
    groupData,
    storeData,
    changeHandler,
    fromDateChangeHander,
    toDateChangeHander,
    selectCategoryHandler,
    selectGroupHandler,
    selectStoreHandler,
    resetHandler
  } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const trigger = useRef(null)
  const dropdown = useRef(null)
  const [form] = Form.useForm()

  useEffect(() => {
    setParam?.({ ...param, categoryId: '',storeId:'', groupId: '',fromDate:'',toDate:'',code:'', })
    form.resetFields()  
  },[])

  // close on click outside
  /*useEffect(() => {
    const clickHandler = (pros: { target: any }) => {
      if (
        !dropdownOpen ||
        (dropdown?.current as any).contains(pros.target) ||
        (trigger.current as any).contains(pros.target)
      )
        return

      setDropdownOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })*/

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = (props: { keyCode: any }) => {
      if (!dropdownOpen || props.keyCode !== 224) return
      setDropdownOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  return (
    <div className="relative inline-flex ml-3">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full ${
          dropdownOpen && 'bg-slate-200'
        }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">{'Filters'}</span>
        <span className='text-black'>      <Icon icon='AlignCenterOutlined'></Icon>
</span>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full w-120 right-0 -mr-108  sm:mr-0 w-72 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-auto mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        appear={undefined}
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
         >
         <Form
          name="basic"
          labelCol={{ span: 6 }}
          autoComplete="off"
          className="p-4 bg-[#ffffff] mt-1"
          form={form}
          >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <InputField
                name="code"
                label=""
                placeholder="Code"
                value={param?.code}
                onChange={changeHandler}
                isRequired={false}
              />
             { typeof storeData!='undefined' && storeData?.length>0 && <SelectField
                name="store"
                label=""
                placeholder="Store"
                isRequired={false}
                onChange={selectStoreHandler}
                dataOptions={
                  storeData?.map((item) => {
                    return { id: item.id, name: item.name }
                  }) as never
                }
              />}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <DateField
                name="fromDate"
                label=""
                placeholder="From Date"
                value={param?.fromDate}
                onChange={fromDateChangeHander}
                isRequired={false}
              />
              <DateField
                name="toDate"
                label=""
                placeholder="To Date"
                value={param?.toDate}
                onChange={toDateChangeHander}
                isRequired={false}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
        
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
             {  typeof categoryData!='undefined' && categoryData?.length>0 && <SelectField
                name="category"
                label=""
                placeholder="category"
                isRequired={false}
                onChange={selectCategoryHandler}
                dataOptions={
                  categoryData?.map((item) => {
                    return { id: item.id, name: item.name,description:item.description }
                  }) as never
                }
              />}
           {typeof groupData!='undefined' && groupData?.length>0 &&  <SelectField
                name="group"
                label=""
                placeholder="group"
                isRequired={false}
                onChange={selectGroupHandler}
                dataOptions={
                  groupData?.map((item) => {
                    return { id: item.id, name: item.name }
                  }) as never
                }
              />}
              <div className='flex justify-between'> <ButtonField
              onClick={(e) => {
                resetHandler?.()
                form.resetFields()  
              }}
              value="Reset"
              style={{ iconColor: COLORS.black, border: 1, iconFont: 12,background:COLORS.lightGray2 }}
              
              icon="ReloadOutlined"
            ></ButtonField>
             <ButtonField
              onClick={(e) => {
               setDropdownOpen(false) 
              }}
              value="Close"
              style={{ iconColor: COLORS.black, border: 1, iconFont: 12,background:COLORS.lightGray2 }}
              
              icon="CloseOutlined"
            ></ButtonField></div>
             
            
            </Col>
          </Row>
        </Form>
        </div>
      </Transition>
    </div>
  )
})
export default Filter
