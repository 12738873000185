import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files
import translationEN from 'locales/en/translation.json';
import translationAm from 'locales/am/translation.json';
const resources = {
  en: {
    translation: translationEN
  },
  am: {
    translation: translationAm
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    keySeparator: false,
    interpolation: {
    escapeValue: false,
    },
  });

export default i18n;