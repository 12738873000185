import MessageField from "common/ui/messageField";
import { UserModel } from "model";
import { Navigate, Outlet } from "react-router-dom";


const ProtectedRoute = (props:{
    user?:UserModel,
    redirectPath?:string,
    children?:any,
  }) => {
   
    if (typeof props.user=='undefined' || typeof props.user==null || typeof props.user.id=='undefined') {
    
      MessageField({type:'info',content:"Please Login First"})
      return <Navigate to={'/login'} replace />;
    }
    else
    return props.children ? props.children : <Outlet />;  };

  export default ProtectedRoute;