import { Form, Select } from 'antd'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'
import Icon from 'common/component/icons/customIcon'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import ButtonField from './ButtonField'

const toLower = (value: any) => {
  return value.toLowerCase()
}

interface Props {
  value: string
  label?: string
  name: string
  isEditable?: boolean
  mode?: 'tags' | 'multiple'
  type: 'text' | 'email' | 'number' | 'tel' | 'password'
  placeholder?: string
  onChange?: (
    value: string,
    option:
      | DefaultOptionType
      | BaseOptionType
      | (DefaultOptionType | BaseOptionType)[],
  ) => void
  onSearch?: ((value: string) => void) | undefined
  onSelect?: ((value: string) => void) | undefined
  isRequired?: boolean
  feedback?: string
  isInvalid?: boolean
  dataOptions: { id: string; name: string; description?: string }[]
  loading?: boolean
  notFoundContent?: string
}

const SelectField = (props: Props) => {
  const {
    value,
    label,
    name,
    onChange,
    onSelect,
    placeholder,
    isRequired,
    isInvalid,
    mode,
    dataOptions,
    onSearch,
    loading,
    notFoundContent,
  } = props
  const { t } = useTranslation()
  const id = `${name}TextSelect`
  return (
    <Form.Item
      name={name}
      label={t(label as string)}
      style={{ margin: '0 0 10px' }}
      rules={[
        {
          required: isRequired,
          message: `Please input ${t(label as string)}!`,
        },
      ]}
    >
      <Select
        mode={mode}
        showSearch
        value={value}
        onChange={onChange}
        onSearch={onSearch}
        onSelect={onSelect}
        placeholder={t(placeholder as string)}
        style={{ padding: 0 }}
        virtual={false}
        loading={loading}
        size={'large'}
        notFoundContent={
          <div className=" font-light text-center text-sm">
            {notFoundContent}
          </div>
        }
        filterOption={(input, option) =>
          (option?.value !== null &&
            toLower(option?.value).includes(toLower(input))) ||
          (option?.key !== null &&
            toLower(option?.key).includes(toLower(input)))
        }
      >
        {dataOptions?.map((item: any, index) => (
          <Select.Option
            value={item.id}
            key={item.name + ' ' + item.description}
          >
            <div style={{ fontWeight: 'bold' }}> {item.name}</div>
            <div
              className="flex justify-between"
            
            >
              <div className=' italic text-[10px]'> {item.description}</div>
              <div> {item.quantity}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

SelectField.defaultProps = {
  value: '',
  type: 'text',
  mode: '',
  loading: false,
  notFoundContent: 'No Data',
}

export default SelectField
