/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialState {
  userId?: string;
  source: string;
  menu: string;
}
const initialState: initialState = {
  userId: "",
  source: "",
  menu: "",
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    set(
      state,
      { payload }: PayloadAction<{ userId?: string; source: string }>
    ) {
      state.userId = payload.userId;
      state.source = payload.source;
    },
    reset(state) {
      state.userId = "";
      state.source = "";
    },
    setMenu(state, { payload }: PayloadAction<string>) {
      localStorage.setItem("menu", JSON.stringify(payload));
      state.menu = payload;
    },
    getMenu(state) {
      const menu = localStorage.getItem("menu");
      if (menu != null) state.menu = JSON.parse(menu);
      else state.menu = "home";
    },
  },
});

export const { set, reset, setMenu, getMenu } = menuSlice.actions;
export default menuSlice.reducer;
