import { useEffect, useState } from 'react'
import Loading from '../loader/loader'
import Message from '../message/messge'
import errorMessageParser from 'util/errorMessageParser'

interface Props {
  errorMessage: any
  successMessage: any
  isLoading: boolean
  isError: boolean
}
function StatusBar(props: Props) {
  const { errorMessage, successMessage, isLoading, isError } = props
  const [messageString, setMessageString] = useState('')
  useEffect(() => {
    setMessageString('')
    var message = errorMessageParser(errorMessage)
    setMessageString(message as string)
  }, [errorMessage])
  return (
    <div>
      <div>
        {(errorMessage || successMessage) && (
          <Message
            type={isError == true ? 'error' : 'success'}
            message={successMessage ? successMessage : messageString}
          ></Message>
        )}
      </div>
      <div> {isLoading == true && <Loading></Loading>}</div>
    </div>
  )
}

export default StatusBar
