import repo from "./repository";
import { VoucherTransactionModel ,ParameterModel} from "model";
export default class VoucherTransactionRepository {
  async find(id: string): Promise<VoucherTransactionModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "VoucherTransaction/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,VoucherTransactionModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "VoucherTransaction", { params: param })
      .then((res) => res?.data);
  }
  async findCGSTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "VoucherTransaction/CGSTotal", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "VoucherTransaction/" + id)
      .then((res) => res?.data);
  }
  async save(entity: VoucherTransactionModel): Promise<VoucherTransactionModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "VoucherTransaction", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: VoucherTransactionModel): Promise<VoucherTransactionModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "VoucherTransaction/" + id, entity)
      .then((response) => response.data);
  }
}
