import repo from "./repository";
import { ConnectionStringModel, DataSourceObjectFieldDataModel ,ParameterModel} from "model";
export default class DataSourceObjectFieldDataRepository {
  async find(id: string): Promise<DataSourceObjectFieldDataModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSourceObjectFieldData/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,DataSourceObjectFieldDataModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "DataSourceObjectFieldData", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "DataSourceObjectFieldData/" + id)
      .then((res) => res?.data);
  }
  async save(entity: DataSourceObjectFieldDataModel): Promise<DataSourceObjectFieldDataModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "DataSourceObjectFieldData", entity)
      .then((res) => res?.data);
  }
  async update(id: string, entity: DataSourceObjectFieldDataModel): Promise<DataSourceObjectFieldDataModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "DataSourceObjectFieldData/" + id, entity)
      .then((res) => res?.data);
  }
}
