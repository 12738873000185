import repo from "./repository";
import { ProductModel ,ParameterModel} from "model";
export default class ProductRepository {
  async find(id: string): Promise<ProductModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Product/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,ProductModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Product", { params: param })
      .then((res) => res?.data);
  }
  async findAllForList(param: ParameterModel): Promise<[number,ProductModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Product/ForList", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Product/" + id)
      .then((res) => res?.data);
  }
  async save(entity: ProductModel): Promise<ProductModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Product", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: ProductModel): Promise<ProductModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Product/" + id, entity)
      .then((response) => response.data);
  }
}
