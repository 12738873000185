import { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Row, Col } from 'antd'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'


import { useUserSelector } from 'state/hook'
import { ReceiveDetailModel } from 'model'
import InputField from 'common/ui/inputField'
import SelectField from 'common/ui/selectField'
import { parameterInitialData } from 'constants/index'
import { useQueryClient } from 'react-query'
import ButtonField from 'common/ui/ButtonField'
import { COLORS } from 'assets/stlyes/theme'
import useSources from 'finance/features/accountMapping/hooks/useSources'
import { SalesContext } from '../sales'

function PaymentForm(props:{setShowForm:Function}) {
  const {setShowForm}=props
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const { user } = useSelector(useUserSelector)

  const initialState = {
    debit:0,
    credit:0,
    accountType:' ',
    companyId: user?.companyId,
    createdBy: user?.firstName + '' + user?.lastName,
    updatedBy: user?.firstName + '' + user?.lastName,
  } as ReceiveDetailModel

  const [formData, setFormtData] = useState(initialState)
  const {
    transactionDetailId,
    transactionDetails,
    setTransactionDetails,
    setTransactionDetailId,
    transactionType,
  } = useContext(SalesContext)
  const [param, setParam] = useState({...parameterInitialData,companyId:user?.companyId})

  const { data: sourceList, isLoading: sourceLoading } = useSources(
    formData.accountType as string,user?.companyId as string
  )
  useEffect(() => {
    if (
      typeof transactionDetailId != 'undefined' &&
      transactionDetailId != ''
    ) {
      var item = transactionDetails.filter(
        (a) => a.id == transactionDetailId,
      )?.[0]
      setFormtData(item)
      form.setFieldsValue(item)
    } else {
      resetHandler()
    }
  }, [transactionDetailId])

  const changeHandler = useCallback((e: any) => {
    const { name, value } = e.target
    setFormtData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])
  const selectAccountTypeHandler = useCallback((value: any) => {
    setFormtData((prevState) => ({
      ...prevState,
      accountType: value,
    }))
  }, [])
  const selectSourceHandler = useCallback(
    (value: any) => {
      var record = sourceList?.filter((a) => a.id == value)?.[0]
      setFormtData((prevState) => ({
        ...prevState,
        sourceId: value,
        sourceName: record?.name,
      }))
    },
    [sourceList],
  )
  const onAccountSearch = useCallback((value: any) => {
    setParam((prevState) => ({
      ...prevState,
      searchText: value,
    }))
  }, [])

  const resetHandler = useCallback(() => {
    setFormtData(initialState)
    form.resetFields()
    form.setFieldsValue(initialState)
    setTransactionDetailId('')
  }, [])
  const add = async () => {
    var isDataExist = false
    const newDataState = transactionDetails.map((obj: any) => {
      if (obj.id === formData.id) {
        isDataExist = true
        return formData
      }
      return obj
    })
    if (isDataExist == false) {
      formData.id = uuid()
      newDataState.push(formData)
    }
    setTransactionDetails(newDataState)
    resetHandler()
    setShowForm(false)
  }
  const submitHandler = async () => {
    await form.validateFields().then(() => {
      {
        add()
      }
    })
  }
  return (
    <>
      <div style={{ height: 0 }}></div>
      <div className="p-2 ">
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          autoComplete="off"
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <SelectField
                name="accountType"
                label="Account Type"
                placeholder="Account Type"
                value={formData.accountType}
                onChange={selectAccountTypeHandler}
                isRequired={true}
                dataOptions={
                  [
                    { id: 'Cashier', name: 'Cashier' } ,
                    { id: 'Bank', name: 'Bank' }
                  ]?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                    }
                  }) as never
                }
              />
              <SelectField
                name="sourceName"
                label={formData.accountType}
                placeholder={formData.accountType}
                value={formData.sourceName}
                onChange={selectSourceHandler}
                isRequired={true}
                loading={sourceLoading}
                dataOptions={
                 sourceList?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                      description: item.code,
                    }
                  }) as never
                }
              />
              <Form.Item style={{ margin: '0 0 10px' }}>
                <Row>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>

                  <Col xs={11} sm={11} md={9} lg={9} xl={9}>
                    <InputField
                      type={'number'}
                      name="debit"
                      label="Debit"
                      placeholder="Debit"
                      value={String(formData.debit)}
                      onChange={changeHandler}
                      isRequired={true}
                    />
                  </Col>
                  <Col xs={2} sm={2} md={1} lg={1} xl={1}></Col>

                  <Col xs={11} sm={11} md={10} lg={10} xl={10}>
                    <InputField
                      type={'number'}
                      name="credit"
                      label="Credit"
                      placeholder="Credit"
                      value={String(formData.credit)}
                      onChange={changeHandler}
                      isRequired={true}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col offset={2} xs={24} sm={24} md={4} lg={4} xl={4}></Col>

            <ButtonField
              value={"Add"}
              icon="PlusSquareFilled"
              style={{
                background: COLORS.lightGray2,
                color: COLORS.green,
              }}
              onClick={submitHandler}
            ></ButtonField>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}></Col>
          </Row>
        </Form>
      </div>
    </>
  )
}
export default PaymentForm
