import { Button } from 'antd'
import { COLORS } from '../../assets/stlyes/theme'
import Icon from 'common/component/icons/customIcon'
import { useTranslation } from 'react-i18next'

interface Props {
  value: string
  icon?: string
  style?: {
    background?: string
    borderColor?: string
    border?: number
    color?: string
    height?: number
    padding?: number
    iconFont?: number
    iconColor?:string
  }
  htmlType?: 'submit' | 'button' | 'reset' | undefined
  type?: 'primary'
  disabled: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const ButtonField = (props: Props) => {
  const { value, icon, style, onClick, type, htmlType, disabled } = props
  const { t } = useTranslation()
  const id = `${value}TextArea`
  return (
    <Button
    size={'large'}
      style={{
        ...style,
      }}
      type={type}
      onClick={onClick}
      htmlType={htmlType}
      disabled={!disabled}
    >
      <Icon style={{ fontSize: style?.iconFont ,color:style?.iconColor}} icon={icon as string}></Icon>
     <span >{t(value)}</span> 
    </Button>
  )
}
ButtonField.defaultProps = {
  value: '',
  disabled: true,
  type: 'default',
  style: {
    background: COLORS.lightGray,
    borderColor: COLORS.secondary,
    color: COLORS.seventh,
    alignSelf: 'right',
    height: 28,
    padding: 2,
    iconFont: 1,
    border: 1,
    iconColor:COLORS.seventh,
  },
}

export default ButtonField
