import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./store";
import {
  CompanyModel,
  OperationModel,
  UserModel,
} from "model";

interface initialState {
  user?: UserModel;
  company?: CompanyModel;
  accessToken: any;
  moduleId?:string
  permissions: {
    id: string;
    name: string;
    icon: string;
    operations: OperationModel[];
  }[];
}
const initialState: initialState = {
  user: undefined,
  company: undefined,
  moduleId:'',
  accessToken: "",
  permissions: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess(
      state,
      { payload }: PayloadAction<{ user: UserModel; accessToken: any }>
    ) {
      state.user = payload.user;
      state.accessToken = payload.accessToken;
    },
    setWizard(
      state,
      { payload }: PayloadAction<{ user: UserModel; company?: CompanyModel }>
    ) {
      state.user = payload.user;
      state.company = payload.company;
    },
    setPermissions(state, { payload }: PayloadAction<[]>) {
      state.permissions = payload;
    },
    setModule(state, { payload }: PayloadAction<string>) {
      state.moduleId = payload;
    },
    logoutSuccess(state) {
      localStorage.setItem("user", "");

      state.user = undefined;
      state.accessToken = "";
    },
  },
});
export const { loginSuccess, logoutSuccess, setPermissions,setWizard ,setModule} =
  userSlice.actions;

export const getCurrentSession = (): AppThunk => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user") as string);
  if (user != null) {
    dispatch(
      loginSuccess({
        user: {
          id: (user.user as any).id,
          firstName: (user.user as any).firstName,
          middleName: (user.user as any).middleName,
          lastName: (user.user as any).lastName,
          userName: (user.user as any).userName,
          email: (user.user as any).email,
          companyId: (user.user as any).companyId,
        },
        accessToken: user.accessToken,
      })
    );
  }
};
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
