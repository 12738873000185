import { Form, Input } from 'antd'
import { FocusEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  value: string
  label?: string
  name: string
  isEditable?: boolean
  type: 'text' | 'email' | 'number' | 'tel' | 'password'
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined
  isRequired?: boolean
  maxLength?: number
  isInvalid?: boolean
}

const InputField = (props: Props) => {
  const {
    value,
    label,
    name,
    onChange,
    onBlur,
    placeholder,
    type,
    isRequired,
    isEditable,
    maxLength,
  } = props
  const { t } = useTranslation()
  return (
    <Form.Item
      name={name}
      label={t(label as string)}
      style={{ margin: '0 0 10px' }}
      rules={[
        {
          required: isRequired,
          message: `Please input ${t(label as string)}!`,
        },
        type == 'email'
          ? {
              type: 'email',
              message: 'The input is not valid E-mail!',
            }
          : { required: false, message: `Please input ${t(label as string)}!` },

        type == 'tel'
          ? {
              pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
              message: 'The input is not valid Phone No!',
            }
          : { required: false, message: `Please input ${t(label as string)}!` },
      ]}
    >
      <Input
        value={value}
        onChange={onChange}
        placeholder={t(placeholder as string)}
        onBlur={onBlur}
        name={name}
        type={type}
        size={'large'}
        disabled={!isEditable}
        maxLength={maxLength}
      ></Input>
    </Form.Item>
  )
}

InputField.defaultProps = {
  value: '',
  type: 'text',
  isEditable: true,
}

export default InputField
