import { useCallback, useContext, useState } from 'react'
import { Table } from 'antd'

import type { ColumnsType } from 'antd/lib/table'

import { useBankTransactions } from './hooks/useBankTransactions'
import { parameterInitialData } from 'constants/initialization'
import PaginationPanel from 'common/component/paginationPanel/paginationPanel'
import SearchBar from 'common/component/searchBar/searchBar'
import { useDeleteBankTransaction } from './hooks/useDeleteBankTransaction'
import { useQueryClient } from 'react-query'
import GridAction from 'common/component/gridAction/gridAction'
import MessageField from 'common/ui/messageField'
import { Link } from 'react-router-dom'
import Icon from 'common/component/icons/customIcon'
import { useSelector } from 'react-redux'
import { useUserSelector } from 'state/hook'
import FloatButton from 'common/component/floatButton/floatButton'
import { BankTransactionContext } from './bankTransaction'
import formateDate from 'util/dateFormater'
import errorMessageParser from 'util/errorMessageParser'

function BankTransactionList(props: { editHander: Function }) {
  const { editHander } = props
  const queryClient = useQueryClient()
  const { setShowEdit } = useContext(BankTransactionContext)

  const { user } = useSelector(useUserSelector)
  const [param, setParam] = useState({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const {
    data: bankTransactionList,
    isLoading,
    recordCount,
  } = useBankTransactions(param)
  const {
    mutate: deleteMutation,
    isLoading: deleteLoading,
  } = useDeleteBankTransaction()
  const columns: ColumnsType<any> = [
    {
      title: 'Bank',
      dataIndex: 'bank',
      key: 'bank',
      render: (text, record) => (
        <Link className=" " to={''} onClick={() => editHander(record.id)}>
          <span className="">{text}</span>
          <div className=" text-xs">
            <span className=" text-slate-400">{record.date}</span>{' '}
          </div>
        </Link>
      ),
    },

    {
      title: 'Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      width: 90,
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (text, record) => (
        <span className="">
          {!isNaN(+text) ? Number(text).toLocaleString() : text}
          <span
            className={`${
              record.transactionType == 'Deposit'
                ? 'text-green-500'
                : 'text-red-500'
            }  text-[11px]`}
          >
            {' '}
            <Icon
              style={{ fontSize: 11 }}
              icon={
                record.transactionType == 'Deposit'
                  ? 'ArrowDownOutlined'
                  : 'ArrowUpOutlined'
              }
            ></Icon>
          </span>{' '}
        </span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 90,
      render: (text, record) => (
        <div className="text-[12px] text-slate-400">
          <span className="">
            {formateDate(new Date(record.updatedAt).toDateString())}{' '}
          </span>
          <span className="block">
            {new Date(record.updatedAt).toLocaleTimeString()}
          </span>
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (text, record) => (
        <GridAction
          id={record.id}
          record={record}
          showAdd={false}
          editHandler={editHander}
          deleteHandler={deleteHandler}
        ></GridAction>
      ),
    },
  ]

  const searchTextChangeHandler = (e: any) => {
    const { name, value } = e.target
    setParam((prevState) => ({
      ...prevState,
      searchText: value,
      offset: 0,
      limit: param.limit,
    }))
  }
  const searchHandler = () => {}
  const setPagination = (valObj: any) => {
    setParam((prevState) => ({
      ...prevState,
      offset: valObj.min,
      limit: valObj.max,
    }))
  }

  const deleteHandler = useCallback((id: string) => {
    deleteMutation(id, {
      onSuccess: (data, variables, context) => {
        MessageField({ type: 'success' })
        queryClient.invalidateQueries('bankTransactions')
      },
      onError: (error, variables, context) => {
        var message = errorMessageParser(error)
        MessageField({ type: 'error', content: message as string })
      },
    })
  }, [])
  return (
    <>
      <SearchBar
        submitHandler={searchHandler}
        textChangeHnadler={searchTextChangeHandler}
      ></SearchBar>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={bankTransactionList}
        loading={isLoading}
        pagination={false}
      />
      <PaginationPanel
        total={recordCount as number}
        defaultPageSize={15}
        setPaginationHandler={setPagination}
      ></PaginationPanel>
      <FloatButton
        openForm={() => {
          setShowEdit(true)
        }}
      ></FloatButton>
    </>
  )
}
export default BankTransactionList
