import repo from "./repository";
import { UserCompanyModel ,ParameterModel} from "model";

export default class UserCompanyRepository {
  async find(id: string): Promise<UserCompanyModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "UserCompany/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number, UserCompanyModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "UserCompany", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "UserCompany/" + id)
      .then((res) => res?.data);
  }
  async save(entity: UserCompanyModel): Promise<UserCompanyModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "UserCompany", entity)
      .then((response) => response.data);
  }

  async update(id: string, entity: UserCompanyModel): Promise<UserCompanyModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "UserCompany/" + id, entity)
      .then((response) => response.data);
  }
}
