import { useQuery } from "react-query";

import repository from "service";
import { SalesOrderModel, ParameterModel } from "model";

const salesOrderRepository = new repository.SalesOrderRepository();
let records: SalesOrderModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[SalesOrderModel[], number]> => {
  await salesOrderRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useSalesOrders(param: ParameterModel) {
  var {
    data: salesOrderData,
    isLoading,
    error,
  } = useQuery(["salesOrders", param], () => fetchData(param), {
   /*enabled:
      (typeof param.searchText != "undefined" && param.searchText != "") ||
      (typeof param.categoryId != "undefined" && param.categoryId != "") ||
      (typeof param.category != "undefined" && param.category != ""),*/
  });
  var data = salesOrderData?.[0];
  var recordCount = salesOrderData?.[1];

  return { data, recordCount, isLoading, error };
}
