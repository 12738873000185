/* eslint-disable no-underscore-dangle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionStringModel } from "model";

interface initialState {
  connStrings?: ConnectionStringModel[];
  dataSourceId?: string;
  dataSource?: string;
  dataSourceObjectId?: string;
  dataSourceObject?: string;
  dataSourceObjectFieldId?: string;
  dataSourceObjectField?: string;
}
const initialState: initialState = {
  connStrings: [] as ConnectionStringModel[],
  dataSourceId: "",
  dataSource: "",
  dataSourceObjectId: "",
  dataSourceObject: "",
  dataSourceObjectFieldId: "",
  dataSourceObjectField: "",
};

const DataSourceSlice = createSlice({
  name: "DataSource",
  initialState,
  reducers: {
    setConnStrings(state, { payload }: PayloadAction<[]>) {
      state.connStrings = payload;
    },
    setDataSource(
      state,
      { payload }: PayloadAction<{ id: string; name: string }>
    ) {
      state.dataSourceId = payload.id;
      state.dataSource = payload.name;
    },
    setDataSourceObject(
      state,
      { payload }: PayloadAction<{ id: string; name: string }>
    ) {
      state.dataSourceObjectId = payload.id;
      state.dataSourceObject = payload.name;
    },
    setDataSourceObjectField(
      state,
      { payload }: PayloadAction<{ id: string; name: string }>
    ) {
      state.dataSourceObjectFieldId = payload.id;
      state.dataSourceObjectField = payload.name;
    },
  },
});
export const { setConnStrings, setDataSource, setDataSourceObject ,setDataSourceObjectField} =
  DataSourceSlice.actions;

export default DataSourceSlice.reducer;
