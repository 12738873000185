export default function formateDate(date?: string) {
  if (typeof date == "undefined" || date == "") return "";
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
export  function formateTime(date?: string) {
  if (typeof date == "undefined" || date == "") return "";
  var d = new Date(date),
    hour =  d.getHours() ,
    minute = d.getMinutes(),
    sec =d.getSeconds();
  return [hour, minute, sec].join(":");
}
export function getDayPart()
{
  let now = new Date();

let daypart   = now.getHours() > 5  && now.getHours() <= 12?'Morning':
    now.getHours() > 12 && now.getHours() <= 18?'Afternoon':
    now.getHours() > 18 && now.getHours() <= 22?'Evening':
    now.getHours() > 22 || now.getHours() <= 5?'isNight':''
return daypart;
}
