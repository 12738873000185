import repo from "./repository";
import { ConnectionStringModel, SectionItemModel ,ParameterModel} from "model";
export default class SectionItemRepository {
  async find(id: string): Promise<SectionItemModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItem/" + id)
      .then((res) => res?.data);
  }

  async getChartData(id: string,userId:string,companyId:string): Promise<string[]> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    queryParams.append("userId", userId);
    queryParams.append("companyId", companyId);
  
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItem/ChartData?"+queryParams.toString())
      .then((res) => res?.data);
  }
  async getChartRawData(id: string,userId:string,companyId:string): Promise<string[]> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    queryParams.append("userId", userId);
    queryParams.append("companyId", companyId);
  
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItem/ChartRawData?"+queryParams.toString())
      .then((res) => res?.data);
  }

  async findAll(param: ParameterModel): Promise<[number,SectionItemModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItem", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "SectionItem/" + id)
      .then((res) => res?.data);
  }
  async save(entity: SectionItemModel): Promise<SectionItemModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "SectionItem", entity)
      .then((res) =>res?.data);
  }
  async update(id: string, entity: SectionItemModel): Promise<SectionItemModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "SectionItem/" + id, entity)
      .then((res) => res?.data);
  }
}
