import { ReceiveModel } from "model";
import { useMutation ,UseMutateFunction} from "react-query";
import repository from "service";

interface updateResult {
  mutate: UseMutateFunction<ReceiveModel, unknown, any, unknown>
  isLoading: boolean
  isError: boolean
}

const receiveRepository = new repository.ReceiveRepository();

const unPostReceive =  async (id: any): Promise<ReceiveModel> => {
  return await receiveRepository.unPost(id);
};

export function useUnPostReceive():updateResult {
 const {mutate,  isLoading, isError } = useMutation(unPostReceive)

var result: updateResult = {mutate,isLoading,isError}  ;
return result;
}
