
export const COLORS = {
    // base colors
    primary: "#37A34A ", // blue
    secondary: "#c3deed",   // gray
    third:'#F4AF3F',
    fourth:'#878b89',
    seventh:'#179673',
    fifth:'#3684ba',
    sixth:'#4e958b',
    // colors
    black: "#1E1F20",
    white: "#FFFFFF",
    red: "#990d1f",
    yellow:'#f4af3f',
    lightGray: "#F5F5F6",
    lightGray2: "#F6F6F7",
    lightGray3: "#EFEFF1",
    lightGray4: "#F8F8F9",
    lightGray5:'#dbdbdd',
    transparent:"transparent",
    darkgray: '#898C95',
    darkgray1:'#5c72a7',
    lightGreen:'#e2f0f5',
    green:'#22c55e'
};

export const SIZES = {
    // global sizes
    base: 8,
    font: 14,
    radius: 30,
    padding: 10,
    padding2: 12,

    // font sizes
    largeTitle: 30,
    h1: 30,
    h2: 22,
    h3: 20,
    h4: 18,
    h5: 16,
    h6: 12,
    body1: 30,
    body2: 20,
    body3: 16,
    body4: 14,
    body5: 12,

};




const appTheme = { COLORS, SIZES };

export default appTheme;