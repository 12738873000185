import repo from "./repository";
import { SupplierModel ,ParameterModel, VatReportModel} from "model";
export default class SupplierRepository {
  async find(id: string): Promise<SupplierModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Supplier/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,SupplierModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Supplier", { params: param })
      .then((res) => res?.data);
  }
  async findAllVatReport(param: VatReportModel): Promise<SupplierModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Supplier/VatReport", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Supplier/" + id)
      .then((res) => res?.data);
  }
  async save(entity: SupplierModel): Promise<SupplierModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Supplier", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: SupplierModel): Promise<SupplierModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Supplier/" + id, entity)
      .then((response) => response.data);
  }
}
