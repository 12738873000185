import repo from "./repository";
import { PurchaseModel ,ParameterModel, VatReportModel} from "model";
export default class PurchaseRepository {
  async find(id: string): Promise<PurchaseModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Purchase/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,PurchaseModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Purchase", { params: param })
      .then((res) => res?.data);
  }
  async findAllVatReport(param: VatReportModel): Promise<PurchaseModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Purchase/VatReport", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Purchase/" + id)
      .then((res) => res?.data);
  }
  async save(entity: PurchaseModel): Promise<PurchaseModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Purchase", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: PurchaseModel): Promise<PurchaseModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Purchase/" + id, entity)
      .then((response) => response.data);
  }
}
