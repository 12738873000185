import { useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from './store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useUserSelector = (state: RootState) => state.user;
export const useDataSourceSelector = (state: RootState) => state.dataSource;
export const useLanguageSelector = (state: RootState) => state.language;
export const useMenuSelector = (state: RootState) => state.menu;
export const useProductSelector = (state: RootState) => state.product;
export const useSectionSelector = (state: RootState) => state.section;


