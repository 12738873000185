import { useQuery } from "react-query";

import repository from "service";
import { MachineModel,ParameterModel } from "model";

const machineRepository = new repository.MachineRepository();
let records: MachineModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[MachineModel[], number]> => {
  await machineRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useMachines(param: ParameterModel) {
  var {
    data: machineData,
    isLoading,
    error,
  } = useQuery(["machines", param], () => fetchData(param), {});
  var data = machineData?.[0];
  var recordCount = machineData?.[1];

  return { data, recordCount, isLoading, error };
}
