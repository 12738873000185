import { useState } from 'react'
import {  Table } from 'antd'
import { useSelector } from 'react-redux'

import { useUserSelector } from 'state/hook'
import { parameterInitialData } from 'constants/index'
import { ColumnsType } from 'antd/es/table'
import { useStores } from 'inventory/features/store/hooks/useStores'
import SearchBar from 'common/component/searchBar/searchBar'
import { useTins } from 'vat/features/tin/hooks/useTins'
import { useFuelTransactions } from './hooks/useFuelTransactions'
import { useFuelTanks } from 'gasStation/features/fuelTank/hooks/useFuelTanks'

function DipSalesReport() {
  const { user } = useSelector(useUserSelector)
  const [param, setParam] = useState({
    ...parameterInitialData,
    companyId: user?.companyId,
  })

  const { data: fuelTankList, isLoading: fuelTankLoading } = useFuelTanks(param)
  const { data: storeList } = useStores({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: tinList } = useTins({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const { data: fuelTransactionList, isLoading: fuelTransactionLoading } = useFuelTransactions({
    ...param,
  })


  const dipReadingColumns: ColumnsType<any> = [
   
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
    },
    {
      title: 'fuelTank',
      dataIndex: 'fuelTank',
      key: 'fuelTank',
      width: 100,
    },
    {
      title: 'Opening Balance',
      dataIndex: 'previousReading',
      key: 'previousReading',
      width: 100,
    },
    {
      title: 'Purchase',
      dataIndex: 'purchase',
      key: 'purchase',
      width: 100,
    },
    {
      title: 'Meter Sales',
      dataIndex: 'meterSales',
      key: 'meterSales',
      width: 100,
    },
    {
      title: 'Loss',
      dataIndex: 'loss',
      key: 'loss',
      width: 100,
    },
    {
      title: 'Net Sales',
      dataIndex: 'netSales',
      key: 'netSales',
      width: 100,
    },
    {
      title: 'Book Stock',
      dataIndex: 'meterSales',
      key: 'meterSales',
      width: 100,
    },
    {
      title: 'Dip Stock',
      dataIndex: 'currentReading',
      key: 'currentReading',
      width: 100,
    },
    {
      title: 'Tank Sales',
      dataIndex: 'tankSales',
      key: 'tankSales',
      width: 100,
    },
    {
      title: 'Variance',
      dataIndex: 'variance',
      key: 'variance',
      width: 100,
    },
  ]

  const searchHandler = () => {}
  const searchTextChangeHandler = (e: any) => {
    const { name, value } = e.target
    setParam((prevState) => ({
      ...prevState,
      searchText: value,
    }))
  }

  return (
    <div className="p-2">
      <div style={{ height: 0 }}></div>
      <SearchBar
        param={param}
        setParam={setParam}
        categoryData={fuelTankList}
        groupData={tinList}
        storeData={storeList}
        submitHandler={searchHandler}
        textChangeHnadler={searchTextChangeHandler}
      ></SearchBar>
      <div className="font-bold m-2 text-lg ">Fuel Dip Sales Report</div>
      <Table
        columns={dipReadingColumns}
        rowKey="id"
        dataSource={fuelTransactionList}
        loading={fuelTransactionLoading}
        pagination={false}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
             
              <Table.Summary.Cell index={3}>
                {' '}
                {Number(
                  fuelTransactionList?.reduce(
                    (sum:any, a:any) => sum + (a.purchase as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10} className="font-bold">
                {Number(
                  fuelTransactionList?.reduce(
                    (sum:any, a:any) =>
                      sum + (a.variance as number),
                    0,
                  ),
                ).toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
export default DipSalesReport
