import { useCallback, useContext } from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import GridAction from 'common/component/gridAction/gridAction'

import { SalesContext } from '../sales'

function SalesItemList(props: { setShowForm: Function }) {
  const { setShowForm } = props
  const { salesitems, setItems, setItemId } = useContext(SalesContext)

  const columns: ColumnsType<any> = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text, record) => (
        <Link
          className=" text-green-700"
          to={''}
          onClick={() => {
            editHandler(record.id)
            setShowForm(true)
          }}
        >
          <span className="font-semibold text-slate-700">{text}</span>
          <div>
            <span className=" text-slate-700 text-xs">
              {' '}
              Quntity: {record.quantity} Unit Price:{record.unitPrice}
            </span>
          </div>
        </Link>
      ),
    },

    {
      title: 'Action',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (text, record) => (
        <GridAction
          id={record.id}
          record={record}
          showAdd={false}
          editHandler={() => {
            editHandler(record.id)
            setShowForm(true)
          }}
          deleteHandler={deleteHandler}
        ></GridAction>
      ),
    },
  ]

  const deleteHandler = useCallback(
    (id: string) => {
      const newDataState = salesitems.filter((obj: any) => obj.id != id)
      setItems(newDataState)
    },
    [salesitems],
  )
  const editHandler = useCallback(
    (id: string) => {
      setItemId(id)
    },
    [salesitems],
  )
  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={salesitems}
        pagination={false}
      />
    </>
  )
}
export default SalesItemList
