
import * as icons from '@ant-design/icons'
import { COLORS } from 'assets/stlyes/theme'
import { createElement } from 'react'

const Icon = (props: {
  icon: string
  style?: {
    background?: string
    borderColor?: string
    color?: string
    height?: number
    width?:number
    padding?: number,
    fontSize?:any
    fontWeight?:string
  }
}) => {
  const { icon , style} = props
  const antIcon: {
    [key: string]: any
  } = icons
  return createElement(antIcon[icon],{style:{...style}})
}
Icon.defaultProps = {

  style: {
    borderColor: COLORS.secondary,
   // color: COLORS.lightGray,
    alignSelf: 'right',
    padding: 2,
    fontSize:17,
  },
}
export default Icon
