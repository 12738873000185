import repo from "./repository";
import { SettingModel ,ParameterModel} from "model";
export default class SettingRepository {
  async find(id: string): Promise<SettingModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Setting/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,SettingModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Setting", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Setting/" + id)
      .then((res) => res?.data);
  }
  async save(entity: SettingModel): Promise<SettingModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Setting", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: SettingModel): Promise<SettingModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Setting/" + id, entity)
      .then((response) => response.data);
  }
}
