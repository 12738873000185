import { EventHandler, useState, useEffect } from 'react'
import { Pagination } from 'antd'

function PaginationPanel(props: {
  total: number
  defaultPageSize: number
  setPaginationHandler: EventHandler<any>
}) {

  const initial = { min: 0, max: props.defaultPageSize }
  const [pageMinMax, setPageMinMax] = useState(initial)

  useEffect(() => {
    props.setPaginationHandler(pageMinMax)
  }, [pageMinMax])

  const changeHandler = (value: number, size: number) => {
    console.log(value)
    if (value <=1) {
      setPageMinMax({...initial,max:size})
    } else {
      setPageMinMax({
        min: (value - 1) * size,
        max: size,
      })
    }
  }
  const sizeChangeHandler = (size: number) => {
    setPageMinMax({
      min: (1 - 1) * size,
      max: size,
    })
  }
  const itemRender = (_:any, type:string, originalElement:any) => {
    console.log(type, "a");
    if (type === "prev") {
      return <a className=' bg-slate-300 text-xs p-2 pl-4 pr-4 text-[#fff] rounded-lg'>Back</a>;
    }
    if (type === "next") {
      return <a className=' bg-green-600 text-xs p-2 pl-4 pr-4 text-[#fff] rounded-lg'>Next</a>;
    }
    return originalElement;
  };
  return (
    <div style={{ padding: 5 }}>
      <Pagination
        defaultCurrent={1}
        defaultPageSize={props.defaultPageSize}
        onChange={changeHandler}
        total={props.total}
        onShowSizeChange={(size: number) => sizeChangeHandler(size)}
        itemRender={itemRender}
      ></Pagination>
    </div>
  )
}

export default PaginationPanel
