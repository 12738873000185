import { useQuery } from "react-query";

import repository from "service";
import { BankTransactionModel } from "model";

const BankTransactionRepository = new repository.BankTransactionRepository();

async function getBankTransactionById(bankTransactionId: string): Promise<BankTransactionModel> {
  return await BankTransactionRepository.find(bankTransactionId);
}
export default function useBankTransaction(bankTransactionId: string) {
  return useQuery(["bankTransaction", bankTransactionId], () => getBankTransactionById(bankTransactionId), {
    enabled: typeof bankTransactionId != "undefined" && bankTransactionId != "",
  });
}
