import { useQuery } from "react-query";

import repository from "service";
import { TinModel,ParameterModel } from "model";

const tinRepository = new repository.TinRepository();
let records: TinModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[TinModel[], number]> => {
  await tinRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useTins(param: ParameterModel) {
  var {
    data: tinData,
    isLoading,
    error,
  } = useQuery(["tins", param], () => fetchData(param), {});
  var data = tinData?.[0];
  var recordCount = tinData?.[1];

  return { data, recordCount, isLoading, error };
}
