import { useQuery } from "react-query";
import repository from "service";

const AccountMappingRepository = new repository.AccountMappingRepository();

async function getSources(
  accountType: string,
  companyId: string
): Promise<any[]> {
  return await AccountMappingRepository.findAllSource(accountType, companyId);
}
export default function useSources(accountType: string, companyId: string) {
  return useQuery(
    ["sources", accountType],
    () => getSources(accountType, companyId),
    {
      enabled:
        typeof accountType != "undefined" &&
        accountType != "" &&
        typeof companyId != "undefined" &&
        companyId != "",
    }
  );
}
