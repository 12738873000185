import { useQuery } from "react-query";

import repository from "service";
import { FuelTankModel,ParameterModel } from "model";

const fuelTankRepository = new repository.FuelTankRepository();
let records: FuelTankModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[FuelTankModel[], number]> => {
  await fuelTankRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useFuelTanks(param: ParameterModel) {
  var {
    data: fuelTankData,
    isLoading,
    error,
  } = useQuery(["fuelTanks", param], () => fetchData(param), {});
  var data = fuelTankData?.[0];
  var recordCount = fuelTankData?.[1];

  return { data, recordCount, isLoading, error };
}
