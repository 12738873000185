
import { message, Modal } from 'antd'

interface Props {
  content?: string
  type?: 'success' | 'error' | 'info'
}

const MessageField = (props: Props) => {
  const {  content, type } = props

  return type == 'success'
    ? message.success({
        content:  "successfully done!" ,
      })
    : type == 'info'
    ? message.success({
        content:  content,
       })
    : message.error({
        content:content as string,
        duration:30
      })
}
MessageField.defaultProps = {
  type: 'success',
  content: 'successfully done!',
}

export default MessageField
