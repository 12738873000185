import { useQuery } from "react-query";

import repository from "service";
import { ParkingModel,ParameterModel } from "model";

const parkingRepository = new repository.ParkingRepository();
let records: ParkingModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[ParkingModel[], number]> => {
  await parkingRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useParkings(param: ParameterModel) {
  var {
    data: parkingData,
    isLoading,
    error,
  } = useQuery(["parkings", param], () => fetchData(param), {});
  var data = parkingData?.[0];
  var recordCount = parkingData?.[1];

  return { data, recordCount, isLoading, error };
}
