import repo from "./repository";
import { ConnectionStringModel, SectionItemFieldModel ,ParameterModel} from "model";
export default class SectionItemFieldRepository {
  async find(id: string): Promise<SectionItemFieldModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItemField/" + id)
      .then((res) => res?.data);
  }
  async getDatabase(connString: ConnectionStringModel): Promise<string[]> {
    console.log(connString)
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItemField/Database" , { params:connString})
      .then((res) => res?.data);
  }
  async getObject(connString: ConnectionStringModel): Promise<string[]> {
    console.log(connString)
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItemField/DatabaseObject" , { params:connString})
      .then((res) => res?.data);
  }

  async findAll(param: ParameterModel): Promise<[number,SectionItemFieldModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "SectionItemField", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "SectionItemField/" + id)
      .then((res) => res?.data);
  }
  async save(entity: SectionItemFieldModel): Promise<SectionItemFieldModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "SectionItemField", entity)
      .then((res) => res?.data);
  }
  async update(id: string, entity: SectionItemFieldModel): Promise<SectionItemFieldModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "SectionItemField/" + id, entity)
      .then((res) => res?.data);
  }
}
