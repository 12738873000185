import repo from "./repository";
import { StoreModel ,ParameterModel} from "model";
export default class StoreRepository {
  async find(id: string): Promise<StoreModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Store/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,StoreModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Store", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Store/" + id)
      .then((res) => res?.data);
  }
  async save(entity: StoreModel): Promise<StoreModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Store", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: StoreModel): Promise<StoreModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Store/" + id, entity)
      .then((response) => response.data);
  }
}
