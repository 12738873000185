import "./App.css";

import { QueryClient, QueryClientProvider } from "react-query";
import { Suspense, useEffect } from "react";
import HomeRoutes from "routing/route";
import { useAppDispatch, useLanguageSelector } from "state/hook";
import { useSelector } from "react-redux";
import { getLanguage } from "state/languageReducer";
import i18n from 'i18n'
import { getCurrentSession } from "state/userReducer";
import Loading from "common/component/loader/loader";
import { Helmet } from "react-helmet-async";
const queryClient = new QueryClient({});

function App() {
  const dispatch = useAppDispatch()
  const { language } = useSelector(useLanguageSelector)
  
  useEffect(() => {
    dispatch(getCurrentSession())
    dispatch(getLanguage())
  }, [])
  useEffect(() => {
    if (language != '') i18n.changeLanguage(language)
  }, [language])

  return (
    <QueryClientProvider client={queryClient}>
         <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
          worker-src blob:;
          default-src 'self' ${ process.env.REACT_APP_API_URL};
          style-src 'self' 'unsafe-inline'; 
          base-uri 'self' ${ process.env.REACT_APP_API_URL};
          img-src 'self' ${ process.env.REACT_APP_File_URL};
          media-src 'self' ${ process.env.REACT_APP_File_URL};
        `}
        ></meta>
      </Helmet>
        <Suspense fallback={<Loading></Loading>}>
        <HomeRoutes></HomeRoutes>     
        </Suspense>
 
    </QueryClientProvider>
  );
}

export default App;
