import { ModuleModel,ParameterModel } from "model";
import repo from "./repository";


export default class ModuleRepository {
  async find(id: string): Promise<ModuleModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Module/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,ModuleModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Module", { params: param })
      .then((res) => res?.data);
  }
  async findAllWithOperations(id: string): Promise<ModuleModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Module/ModuleWithOperations/" + id)
      .then((res) => res?.data.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Module/" + id)
      .then((res) => res?.data);
  }
  async save(entity: ModuleModel): Promise<ModuleModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Module", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: ModuleModel): Promise<ModuleModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Module/" + id, entity)
      .then((response) => response.data);
  }
}
