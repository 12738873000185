import { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Row, Col, DatePickerProps } from 'antd'
import { useSelector } from 'react-redux'

import useBankTransaction from './hooks/useBankTransaction'
import { BankTransactionContext } from './bankTransaction'
import { useSaveBankTransaction } from './hooks/useSaveBankTransaction'
import { useUserSelector } from 'state/hook'
import { BankTransactionModel } from 'model'
import MessageField from 'common/ui/messageField'
import StatusBar from 'common/component/statusBar/statusBar'
import ActionBar from 'common/component/actionBar/actionBar'
import InputField from 'common/ui/inputField'
import { useQueryClient } from 'react-query'
import DateField from 'common/ui/dateField'
import SelectField from 'common/ui/selectField'
import { useBanks } from '../bank/hooks/useBanks'
import { parameterInitialData } from 'constants/initialization'
import BankTransactionDetail from './details'
import formateDate from 'util/dateFormater'
import dayjs from 'dayjs'
const dateFormat = 'YYYY-MM-DD'

function BankTransactionForm() {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const { user } = useSelector(useUserSelector)

  const initialState = {
    companyId: user?.companyId,
    isDefault: false,
    createdBy: user?.firstName + '' + user?.lastName,
    updatedBy: user?.firstName + '' + user?.lastName,
  } as BankTransactionModel

  const [formData, setFormtData] = useState(initialState)
  const {
    bankTransactionId,
    setTransactionType,
    transactionDetails,
    setTransactionDetails,
  } = useContext(BankTransactionContext)
  const {
    data: bankTransactionData,
    isLoading: bankTransactionLoading,
  } = useBankTransaction(bankTransactionId)
  const { data: bankList } = useBanks({
    ...parameterInitialData,
    companyId: user?.companyId,
  })
  const {
    mutate: saveMutation,
    isLoading: saveLoading,
    error,
    isError,
    isSuccess,
  } = useSaveBankTransaction()

  useEffect(() => {
    if (typeof bankTransactionData != 'undefined') {
      setFormtData({
        ...bankTransactionData,
        date: formateDate(bankTransactionData.date),
      })
      form.setFieldsValue({
        ...bankTransactionData,
        date: dayjs(bankTransactionData.date),
      })

      setTransactionType(bankTransactionData.transactionType as string)
      setTransactionDetails(bankTransactionData.details as never)
    } else {
      resetHandler()
    }
  }, [bankTransactionData])

  const changeHandler = useCallback((e: any) => {
    const { name, value } = e.target
    setFormtData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])
  const dateChangeHander: DatePickerProps['onChange'] = useCallback(
    (date: any, dateString: string) => {
      setFormtData((prevState) => ({
        ...prevState,
        date: dateString,
      }))
    },
    [],
  )
  const selectBankHandler = useCallback(
    (value: any) => {
      var record = bankList?.filter((a) => a.id == value)[0]
      setFormtData((prevState) => ({
        ...prevState,
        bankId: value,
        bankName: record?.name,
        //  accountId: record?.accountId,
        //account: record?.account,
      }))
    },
    [bankList],
  )
  const selectMethodHandler = useCallback((value: any) => {
    setFormtData((prevState) => ({
      ...prevState,
      method: value,
    }))
  }, [])
  const selectTransactionTypeHandler = useCallback((value: any) => {
    setFormtData((prevState) => ({
      ...prevState,
      transactionType: value,
    }))
    setTransactionType(value as string)
  }, [])
  const selectReasoneHandler = useCallback((value: any) => {
    setFormtData((prevState) => ({
      ...prevState,
      reason: value,
    }))
    setTransactionType(value as string)
  }, [])
  const resetHandler = useCallback(() => {
    setFormtData({
      ...initialState,
      date: formateDate(new Date().toLocaleString()),
    })
    form.resetFields()
    form.setFieldsValue({
      ...initialState,
      date: dayjs(new Date().toLocaleString()),
    })
    setTransactionDetails([])
  }, [])
  const submitHandler = async () => {
    saveMutation(
      { ...formData, details: transactionDetails },
      {
        onSuccess: (data, variables, context) => {
          resetHandler()
          MessageField({ type: 'success' })
          queryClient.invalidateQueries('bankTransactions')
        },
      },
    )
  }

  return (
    <>
      <div style={{ height: 0 }}></div>
      <div className="formPanel">
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          autoComplete="off"
        >
          <Row>
            <Col className="p-0 m-0" xs={24} sm={24} md={4} lg={4} xl={4}></Col>
            <StatusBar
              errorMessage={error}
              isLoading={saveLoading || bankTransactionLoading}
              isError={isError}
              successMessage={isSuccess ? '' : ''}
            ></StatusBar>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}></Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <SelectField
                name="bank"
                label="Bank"
                placeholder="Bank"
                value={formData.bankName}
                onChange={selectBankHandler}
                isRequired={true}
                dataOptions={
                  bankList?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                    }
                  }) as never
                }
              />
              <SelectField
                name="transactionType"
                label="Transaction Type"
                placeholder="Transaction Type"
                value={formData.transactionType}
                onChange={selectTransactionTypeHandler}
                isRequired={true}
                dataOptions={
                  [
                    { id: 'Deposit', name: 'Deposit' },
                    { id: 'Transfer', name: 'Transfer' },
                    { id: 'Withdrawal', name: 'Withdrawal' },
                  ]?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                    }
                  }) as never
                }
              />
              <DateField
                name="date"
                label="Date"
                value={formData.date}
                onChange={dateChangeHander}
                isRequired={true}
              />
              <InputField
                type={'number'}
                name="amount"
                label="Amount"
                placeholder="Amount"
                value={String(formData.amount)}
                onChange={changeHandler}
                isRequired={true}
              />
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <SelectField
                name="method"
                label="Method"
                placeholder="Method"
                value={formData.method}
                onChange={selectMethodHandler}
                isRequired={true}
                dataOptions={
                  [
                    { id: 'Mobile Transfer', name: 'Mobile Transfer' },
                    { id: 'TT', name: 'TT' },
                    { id: 'Bank Deposit', name: 'Bank Deposit' },
                  ]?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                    }
                  }) as never
                }
              />
              <InputField
                name="referenceNo"
                label="Reference No"
                placeholder="Reference No"
                value={String(formData.referenceNo)}
                onChange={changeHandler}
                isRequired={false}
              />
              <SelectField
                name="reason"
                label="Reason"
                placeholder="Reason"
                value={formData.reason}
                onChange={selectReasoneHandler}
                isRequired={true}
                dataOptions={
                  [
                    { id: 'Sales', name: 'Sales' },
                    { id: 'Purchase', name: 'Purchase' },
                    { id: 'Expense', name: 'Expense' },
                    { id: 'Transfer', name: 'Transfer' },
                    { id: 'Bank Charge', name: 'Bank Charge' },
                    { id: 'Other', name: 'Other' },
                  ]?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                    }
                  }) as never
                }
              />
            </Col>
          </Row>
        </Form>
        <BankTransactionDetail />
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>
          <ActionBar
            showDelete={false}
            showClear={false}
            disableAdd={
              typeof transactionDetails != 'undefined' &&
              transactionDetails.length > 0
                ? false
                : true
            }
            onSave={submitHandler}
            onrest={resetHandler}
          />

          <Col xs={24} sm={24} md={18} lg={18} xl={18}></Col>
        </Row>
      </div>
    </>
  )
}
export default BankTransactionForm
