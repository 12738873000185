import repo from "./repository";
import { RolePermissionModel ,ParameterModel} from "model";

export default class RolePermissionRepository {
  async find(id: string): Promise<RolePermissionModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "RolePermission/" + id)
      .then((res) => res?.data);
  }
  async findAll(
    param: ParameterModel
  ): Promise<[number, RolePermissionModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "RolePermission", { params: param })
      .then((res) => res?.data);
  }
  async findAllRole(
    param: ParameterModel
  ): Promise<[number, RolePermissionModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "RolePermission/RoleList", {
        params: param,
      })
      .then((res) => res?.data);
  }
  async findAllByRole(roleId: string): Promise<RolePermissionModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "RolePermission/ByRoleId/" + roleId)
      .then((res) => res?.data.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "RolePermission/" + id)
      .then((res) => res?.data);
  }
  async save(entity: RolePermissionModel): Promise<RolePermissionModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "RolePermission", entity)
      .then((response) => response.data);
  }
  async saveByRole(
    roleId: string,
    entity: RolePermissionModel[]
  ): Promise<RolePermissionModel[]> {
    return await repo
      .post(
        process.env.REACT_APP_API_URL + "RolePermission/ByRoleId/" + roleId,
        entity
      )
      .then((response) => response.data);
  }
  async update(
    id: string,
    entity: RolePermissionModel
  ): Promise<RolePermissionModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "RolePermission/" + id, entity)
      .then((response) => response.data);
  }
}
