import { useQuery } from "react-query";

import repository from "service";
import { UserModel } from "model";
import {ParameterModel} from "model";

const userRepository = new repository.UserRepository();
let records: UserModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[UserModel[], number]> => {
  await userRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useUsersByCompany(param: ParameterModel) {
  var {
    data: userData,
    isLoading,
    error,
  } = useQuery(["users", param], () => fetchData(param), {enabled:typeof param.companyId!='undefined' && param.companyId!=''});
  var data = userData?.[0];
  var recordCount = userData?.[1];

  return { data, recordCount, isLoading, error };
}
