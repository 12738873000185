import { createContext, useState } from 'react'
import { Row, Col } from 'antd'

import SalesList from './salesList'
import SalesForm from './salesForm'
import FormNavBar from 'common/component/formNavBar/formNav'
import { DeliveryDetailModel, DeliveryItemModel } from 'model'

export const SalesContext = createContext({
  salesId: '',
  salesItemId: '',
  salesitems: [] as DeliveryItemModel[],
  setId: (val: string) => {},
  setItemId: (val: string) => {},
  setItems: (val: DeliveryItemModel[]) => {},
  setShowEdit: (val: boolean) => {},
  transactionDetailId: '',
  transactionType: '',
  setTransactionType: (val: string) => {},
  transactionDetails: [] as DeliveryDetailModel[],
  setTransactionDetailId: (val: string) => {},
  setTransactionDetails: (val: DeliveryDetailModel[]) => {},

})
function Sales() {
  const [showForm, setshowForm] = useState(false)
  const [showDetailForm, setShowDetailForms] = useState(false)
  const [salesId, setSalesId] = useState('')
  const [salesItemId, setSalesItemId] = useState('')
  const [salesitems, setSalesitems] = useState(
    [] as DeliveryItemModel[],
  )
  const [transactionDetailId, setTransactionDetailId] = useState('')
  const [transactionType, setTransactionType] = useState('')
  const [transactionDetails, setTransactionDetails] = useState(
    [] as DeliveryDetailModel[],
  )

  const [showEdit, setshowEdit] = useState(false)
  const onAdd = () => {
    setId('')
    setshowForm(true)
  }
  const onEdit = (id: string) => {
    setId(id)
    setshowForm(true)
  }
  const onList = () => setshowForm(false)
  const setId = (val: string) => setSalesId(val)
  const setItemId = (val: string) => setSalesItemId(val)
  const setItems = (val: DeliveryItemModel[]) => setSalesitems(val)


const setShowEdit = (val: boolean) => {setId('');setshowForm(val)}


  return (
    <div>
      <SalesContext.Provider
        value={{
          salesId,
          salesItemId,
          salesitems,
          transactionDetailId,
          setTransactionDetailId,
          transactionType,
          setTransactionType,
          transactionDetails,
          setTransactionDetails,
          setItemId,
          setItems,
          setId,
          setShowEdit,
        }}
      >
        <FormNavBar
          title={'Sales'}
          showForm={showForm}
          onAdd={onAdd}
          onList={onList}
          hideAdd={true}
        />
        <Row>
          <Col
            className="content"
            span={24}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            {showForm == false && (
              <SalesList editHander={onEdit}></SalesList>
            )}{' '}
            {showForm == true && <SalesForm></SalesForm>}
          </Col>
        </Row>
      </SalesContext.Provider>
    </div>
  )
}
export default Sales
