import { useQuery } from "react-query";

import repository from "service";
import { BankTransactionModel,ParameterModel } from "model";

const bankTransactionRepository = new repository.BankTransactionRepository();
let records: BankTransactionModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[BankTransactionModel[], number]> => {
  await bankTransactionRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useBankTransactions(param: ParameterModel) {
  var {
    data: bankTransactionData,
    isLoading,
    error,
  } = useQuery(["bankTransactions", param], () => fetchData(param), {});
  var data = bankTransactionData?.[0];
  var recordCount = bankTransactionData?.[1];

  return { data, recordCount, isLoading, error };
}
