import repo from "./repository";
import { CashierModel ,ParameterModel} from "model";
export default class CashierRepository {
  async find(id: string): Promise<CashierModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Cashier/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,CashierModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Cashier", { params: param })
      .then((res) => res?.data);
  }
  async findCGSTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Cashier/CGSTotal", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Cashier/" + id)
      .then((res) => res?.data);
  }
  async save(entity: CashierModel): Promise<CashierModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Cashier", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: CashierModel): Promise<CashierModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Cashier/" + id, entity)
      .then((response) => response.data);
  }
}
