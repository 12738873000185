
import RolePermissionRepository from "./rolePermissionRepository";
import RoleRepository from "./roleRepository";
import UserRepository from "./userRepository";
import UserRoleRepository from "./userRoleRepository";
import ModuleRepository from "./moduleRepository";
import OperationRepository from "./operationRepository";
import NotificationRepository from "./notificationRepository";
import CompanyRepository from "./companyRepository";
import ProductRepository from "./productRepository";
import ProductCategoryRepository from "./productCategoryRepository";
import StoreRepository from "./storeRepository";
import BincardRepository from "./bincardRepository";
import DailySalesRepository from "./dailySalesRepository";
import PriceChangeRepository from "./priceChangeRepository";
import UserCompanyRepository from "./userCompanyRepository";
import MachineRepository from "./machineRepository";
import VatTransactionRepository from "./vatTransactionRepository";
import ExpenseRepository from "./expenseRepository";
import ExpenseTypeRepository from "./expenseTypeRepository";
import PurchaseRepository from "./purchaseRepository";
import PurchaseTypeRepository from "./purchaseTypeRepository";
import TinRepository from "./tinRepository";
import TaxTypeRepository from "./taxTypeRepository";
import SupplierRepository from "./supplierRepository";
import PurchaseOrderRepository from "./purchaseOrderRepository";
import ReceiveRepository from "./receiveRepository";
import DocumentSettingRepository from "./documentSettingRepository";
import CustomerRepository from "./customerRepository";
import DeliveryRepository from "./deliveryRepository";
import SalesOrderRepository from "./salesOrderRepository";
import AdjustmentRepository from "./adjustmentRepository";
import ShiftRepository from "./shiftRepository";
import FuelTankRepository from "./fuelTankRepository";
import DipReadingRepository from "./dipReadingRepository";
import MeterReadingRepository from "./meterReadingRepository";
import DispenserRepository from "./dispenserRepository";
import FuelTransactionRepository from "./fuelTransactionRepository";
import BankRepository from "./bankRepository";
import CashierRepository from "./cashierRepository";
import AccountRepository from "./accountRepository";
import VoucherTransactionRepository from "./voucherTransactionRepository";
import AccountMappingRepository from "./accountMappingRepository";
import AccountGroupRepository from "./accountGroupRepository";
import BankTransactionRepository from "./bankTransactionRepository";
import ParkingRepository from "./parkingRepository";
import CarWashRepository from "./carWashRepository";
import VehicleRepository from "./vehicleRepository";
import ReportRepository from "./reportRepository";
import TransportRepository from "./transportRepository";
import SettingRepository from "./settingRepository";
import DataSourceRepository from "./dataSourceRepository";
import DataSourceObjectRepository from "./dataSourceObjectRepository";
import DataSourceObjectFieldRepository from "./dataSourceObjectFieldRepository";
import SectionRepository from "./sectionRepository";
import SectionItemRepository from "./sectionItemRepository";
import SectionItemFieldRepository from "./sectionItemFieldRepository";
import SectionCriteriaRepository from "./sectionCriteriaRepository";
import SectionUserRepository from "./sectionUserRepository";
import DataSourceObjectFieldDataRepository from "./dataSourceObjectFieldDataRepository";

const repositories =
{
    RolePermissionRepository,
    RoleRepository,
    UserRepository,
    UserRoleRepository,
    ModuleRepository,
    OperationRepository,
    NotificationRepository,
    CompanyRepository,
    ProductRepository,
    ProductCategoryRepository,
    StoreRepository,
    BincardRepository,
    DailySalesRepository,
    PriceChangeRepository,
    UserCompanyRepository,
    MachineRepository,
    VatTransactionRepository,
    ExpenseRepository,
    ExpenseTypeRepository,
    PurchaseRepository,
    PurchaseTypeRepository,
    TinRepository,
    TaxTypeRepository,
    SupplierRepository,
    PurchaseOrderRepository,
    ReceiveRepository,
    DocumentSettingRepository,
    CustomerRepository,
    DeliveryRepository,
    SalesOrderRepository,
    AdjustmentRepository,
    ShiftRepository,
    FuelTankRepository,
    MeterReadingRepository,
    DipReadingRepository,
    DispenserRepository,
    FuelTransactionRepository,
    BankRepository,
    CashierRepository,
    AccountRepository,
    VoucherTransactionRepository,
    AccountMappingRepository,
    AccountGroupRepository,
    BankTransactionRepository,
    ParkingRepository,
    CarWashRepository,
    VehicleRepository,
    ReportRepository,
    TransportRepository,
    DataSourceRepository,
    DataSourceObjectRepository,
    DataSourceObjectFieldRepository,
    SectionRepository,
    SectionItemRepository,
    SectionItemFieldRepository,
    SectionCriteriaRepository,
    SectionUserRepository,
    SettingRepository,
    DataSourceObjectFieldDataRepository
    
};

export default repositories;