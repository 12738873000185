import {
  Action,
  ThunkAction,
  configureStore,
} from "@reduxjs/toolkit";
import rootReducer from "state";

const store = configureStore({
  reducer: rootReducer,
});
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export type RootState = ReturnType<typeof rootReducer>;
export default store;
