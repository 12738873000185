import repo from "./repository";
import { ReceiveModel ,ParameterModel, VatReportModel} from "model";
export default class ReceiveRepository {
  async find(id: string): Promise<ReceiveModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Receive/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,ReceiveModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Receive", { params: param })
      .then((res) => res?.data);
  }
  async findAllPurchase(param: ParameterModel): Promise<[number,ReceiveModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Receive/Purchase", { params: param })
      .then((res) => res?.data);
  }
  async findAllVatReport(param: VatReportModel): Promise<ReceiveModel[]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Receive/VatReport", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Receive/" + id)
      .then((res) => res?.data);
  }
  async deletePurchase(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Receive/Purchase/" + id)
      .then((res) => res?.data);
  }
  async save(entity: ReceiveModel): Promise<ReceiveModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Receive", entity)
      .then((response) => response.data);
  }
  async savePurchase(entity: ReceiveModel): Promise<ReceiveModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Receive/Purchase", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: ReceiveModel): Promise<ReceiveModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Receive/" + id, entity)
      .then((response) => response.data);
  }
  async post(id: string): Promise<ReceiveModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Receive/Post/"+id)
      .then((response) => response.data);
  }
  async unPost(id: string): Promise<ReceiveModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Receive/UnPost/"+id)
      .then((response) => response.data);
  }
}
