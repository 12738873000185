import { DeliveryModel } from "model";
import { useMutation ,UseMutateFunction} from "react-query";
import repository from "service";

interface updateResult {
  mutate: UseMutateFunction<DeliveryModel, unknown, any, unknown>
  isLoading: boolean
  isError: boolean
}

const salesRepository = new repository.DeliveryRepository();

const deleteSales =  async (id: any): Promise<DeliveryModel> => {
  return await salesRepository.deleteSales(id);
};

export function useDeleteSales():updateResult {
 const {mutate,  isLoading, isError } = useMutation(deleteSales)

var result: updateResult = {mutate,isLoading,isError}  ;
return result;
}
