import { Button } from 'antd'
import { MouseEventHandler } from 'react'
import Icon from '../icons/customIcon'

interface Props {
  showDelete?: boolean
  showAction?: boolean
  actionName?: string
  showReport?: boolean
  showReject?: boolean
  showClear?: boolean
  showNotify?: boolean
  showAdd?: boolean
  disableAdd?:boolean
  onrest?: MouseEventHandler<HTMLElement>
  onSave?: MouseEventHandler<HTMLElement>
  onDelete?: MouseEventHandler<HTMLElement>
  onAction?: MouseEventHandler<HTMLElement>
  onReport?: MouseEventHandler<HTMLElement>
  onReject?: MouseEventHandler<HTMLElement>
  onNotify?: MouseEventHandler<HTMLElement>
  permission: {
    canAdd?: boolean
    canEdit?: boolean
    canDelete?: boolean
    canApprove?: boolean
  }
}
function ActionBar(props: Props) {
  const {
    onrest,
    onSave,
    onDelete,
    onAction,
    onReport,
    onReject,
    onNotify,
    showAdd,
    showClear,
    showReject,
    showReport,
    actionName,
    showAction,
    showDelete,
    showNotify,
    permission,
    disableAdd
  } = props
  return (
    <>
      {(typeof showClear == 'undefined' || showClear == true) && (
        <Button
          className="border-slate-200 rounded-lg pb-2 justify-center content-center items-center text-xm shadow bg-yellow-400 m-0.5"
          onClick={onrest}
        >
          <span className="text-slate-50 mx-1">
            <Icon icon={'ClearOutlined'}></Icon>
          </span>
          <span className="text-slate-50 text.xs font-light">
            {' '}
            {'  '}
            {'Clear'}
          </span>
        </Button>
      )}
      {(typeof showAdd != 'undefined' || showAdd) &&  (
        <Button
          className=" max-md:w-full border-slate-200 w-40 rounded-lg pb-4 justify-center content-center items-center shadow bg-green-600 m-0.5"
          htmlType="submit"
          onClick={onSave}
          size={'large'}
          disabled={!permission.canAdd || (disableAdd==true)}
          
        > {'     '}
        
          <span className="text-white font-semibold text-md">
            {'  '}
            {'Save'}
          </span>
        </Button>
      )}

      {(typeof showDelete == 'undefined' || showDelete) && (
        <Button
          className=" max-md:w-full border-slate-200 w-40 rounded-lg pb-4 justify-center content-center items-center shadow bg-red-600 m-0.5"
          size={'large'}
      
          onClick={onDelete}
          disabled={!permission.canDelete}
        >
          <span className="text-slate-50 mx-1">
            <Icon icon={'DeleteOutlined'}></Icon>
          </span>
          <span className="text-slate-50 text.xs font-light">
            {' '}
            {'  '}
            {'Delete'}
          </span>
        </Button>
      )}
      {(typeof showAction != 'undefined' || showAction) && (
        <Button
          className="border-slate-200 rounded-lg pb-2 justify-center content-center items-center text-xm shadow bg-green-50 m-0.5"
          onClick={onAction}
        >
          <span className="text-slate-400 mx-1">
            <Icon icon={'BorderOuterOutlined'}></Icon>
          </span>
          <span className="text-slate-500 text.xs font-light">
            {' '}
            {'  '}
            {actionName as string}
          </span>
        </Button>
      )}
      {(typeof showReport != 'undefined' || showReport) && (
        <Button
          className="border-slate-200 rounded-lg pb-2 justify-center content-center items-center text-xm shadow bg-slate-100 m-0.5"
          onClick={onReport}
        >
          <span className="text-slate-400 mx-1">
            <Icon icon={'PrinterOutlined'}></Icon>
          </span>
          <span className="text-slate-500 text.xs font-light">
            {' '}
            {'  '}
            {'Preview'}
          </span>
        </Button>
      )}
      {(typeof showReject != 'undefined' || showReject) && (
        <Button
          className="border-slate-200 rounded-lg pb-2 justify-center content-center items-center text-xm shadow bg-red-100 m-0.5"
          onClick={onReject}
        >
          <span className="text-slate-400 mx-1">
            <Icon icon={'CloseOutlined'}></Icon>
          </span>
          <span className="text-slate-500 text.xs font-light">
            {' '}
            {'  '}
            {'Reject'}
          </span>
        </Button>
      )}
      {(typeof showNotify != 'undefined' || showNotify) && (
        <Button
          className="border-slate-200 rounded-lg pb-2 justify-center content-center items-center text-xm shadow bg-red-50 m-0.5"
          onClick={onNotify}
        >
          <span className="text-slate-400 mx-1">
            <Icon icon={'NotificationOutlined'}></Icon>
          </span>
          <span className="text-slate-500 text.xs font-light">
            {' '}
            {'  '}
            {'Notify'}
          </span>
        </Button>
      )}
    </>
  )
}
ActionBar.defaultProps = {
  showAdd: true,
  permission: {
    canAdd: true,
    canEdit: true,
    canDelete: true,
    canApprove: true,
  },
}
export default ActionBar
