import { useContext, useState } from 'react'
import TransactionDetailList from './transactionDetailList'
import TransactionDetailForm from './transactionDetailForm'
import { Col, Row } from 'antd'

import ButtonField from 'common/ui/ButtonField'
import { COLORS } from 'assets/stlyes/theme'
import { SalesContext } from '../sales'

function SalesDetail() {
  const { transactionDetails, transactionType,setTransactionDetailId } = useContext(
    SalesContext,
  )
  const [showForm, setshowForm] = useState(false)

  return (
    <>
      <div className=" text-md text-md text-slate-500 font-bold"></div>
      <Row>
        <Col
          className=" rounded-lg  border-slate-200 border-dashed border-2 p-2"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <div className=" flex justify-end">
            <div className="mb-2">
              <ButtonField
                value={
                  'Add' +
                  (transactionType == 'Deposit'
                    ? ' Source'
                    : transactionType == 'Transfer'
                    ? ' Transfer To'
                    : transactionType == 'Withdrawal'
                    ? ' Expense'
                    : '') +
                  ' Payment'
                }
                icon="PlusCircleFilled"
                style={{
                  color: COLORS.black,
                  background: COLORS.lightGray2,
                }}
                onClick={() => {
                  setshowForm(!showForm)
                  setTransactionDetailId('')
                }}
              ></ButtonField>
            </div>
          </div>
          {showForm && (
            <TransactionDetailForm
              setShowForm={setshowForm}
            ></TransactionDetailForm>
          )}
          {!showForm && transactionDetails.length > 0 && (
            <TransactionDetailList
              setShowForm={setshowForm}
            ></TransactionDetailList>
          )}
        </Col>
        <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}></Col>
      </Row>
    </>
  )
}
export default SalesDetail
