import { useQuery } from "react-query";

import repository from "service";
import { ProductModel, ParameterModel } from "model";

const productRepository = new repository.ProductRepository();
let records: ProductModel[] = [];
let count = 0;
const fetchData = async (param: any): Promise<[ProductModel[], number]> => {
  await productRepository.findAll(param).then((result: any) => {
    records = result.data;
    count = result.count;
  });
  return [records, count];
};

export function useProductsSearch(param: ParameterModel) {
  var {
    data: productData,
    isLoading,
    error,
  } = useQuery(["productsSearch", param], () => fetchData(param), {
  //  enabled: typeof param.searchText != "undefined" && param.searchText != "",
  });
  var data = productData?.[0];
  var recordCount = productData?.[1];

  return { data, recordCount, isLoading, error };
}
