import { EventHandler, memo, useCallback, useState } from 'react'
import { DatePickerProps, Form, Input, Row } from 'antd'
import { ParameterModel } from 'model'
import Filter from './filter'
import isValidGUID from 'util/validateGuid'

const SearchBar = memo(function (props: {
  submitHandler: Function
  textChangeHnadler: EventHandler<any>
  param?: ParameterModel
  setParam?: Function
  total?: number
  grandTotal?: number
  categoryData?: any[]
  groupData?: any[]
  storeData?: any[]
}) {
  const {
    param,
    setParam,
    categoryData,
    groupData,
    submitHandler,
    total,
    grandTotal,
    storeData,
  } = props

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      submitHandler()
    }
  }
  const changeHandler = useCallback((e: any) => {
    const { name, value } = e.target
    setParam?.({ ...param, [name]: value })
  }, [])

  const fromDateChangeHander: DatePickerProps['onChange'] = useCallback(
    (date: any, dateString: string) => {
      setParam?.({ ...param, fromDate: dateString })
    },
    [],
  )
  const toDateChangeHander: DatePickerProps['onChange'] = useCallback(
    (date: any, dateString: string) => {
      setParam?.({ ...param, toDate: dateString })
    },
    [],
  )
  const selectCategoryHandler = useCallback((value: any) => {
    setParam?.({ ...param, categoryId: value })
  }, [])
  const selectGroupHandler = useCallback((value: any) => {
    if(isValidGUID(value))
    {
      setParam?.({ ...param, groupId: value })
    }

  else
  setParam?.({ ...param, group:value })
  }, [])
  const selectStoreHandler = useCallback((value: any) => {
    setParam?.({ ...param, storeId: value })
  }, [])

  const resetHandler = useCallback((value: any) => {
    setParam?.({
      ...param,
      categoryId:
        typeof categoryData != 'undefined' && categoryData?.length > 0
          ? ''
          : param?.categoryId,
      storeId: '',
      groupId:
        typeof groupData != 'undefined' && groupData?.length > 0
          ? ''
          : param?.groupId,
          group:
          typeof groupData != 'undefined' && groupData?.length > 0
            ? ''
            : param?.group,
      fromDate: '',
      toDate: '',
      code: '',
    })
  }, [])

  return (
    <div style={{ padding: 2 }}>
      <div className="grid grid-flow-col col-span-12">
        <div className=" max-sm:col-span-12  col-span-6 pl-2">
          {' '}
          <Input
            placeholder="searchText"
            name="searchText"
            style={{ fontSize: 12, height: 32, width: '80%' }}
            onChange={props.textChangeHnadler}
            onKeyDown={handleKeyDown}
          />
          {typeof param != 'undefined' && (
            <Filter
              submitHandler={submitHandler}
              changeHandler={changeHandler}
              fromDateChangeHander={fromDateChangeHander}
              toDateChangeHander={toDateChangeHander}
              selectCategoryHandler={selectCategoryHandler}
              selectGroupHandler={selectGroupHandler}
              selectStoreHandler={selectStoreHandler}
              categoryData={categoryData}
              groupData={groupData}
              storeData={storeData}
              resetHandler={resetHandler}
            ></Filter>
          )}{' '}
        </div>
      </div>
      <div className="pt-2 pl-3 text-xs font-semibold text-slate-400">
        {/*typeof grandTotal != 'undefined' &&
          grandTotal > 0 &&
          'Record: ' + total + ' of ' + grandTotal*/}
      </div>
    </div>
  )
})

export default SearchBar
