import repo from "./repository";
import { ParkingModel ,ParameterModel} from "model";
export default class ParkingRepository {
  async find(id: string): Promise<ParkingModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Parking/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number,ParkingModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Parking", { params: param })
      .then((res) => res?.data);
  }
  async findCGSTotal(param: ParameterModel): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "Parking/CGSTotal", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "Parking/" + id)
      .then((res) => res?.data);
  }
  async save(entity: ParkingModel): Promise<ParkingModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "Parking", entity)
      .then((response) => response.data);
  }
  async update(id: string, entity: ParkingModel): Promise<ParkingModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "Parking/" + id, entity)
      .then((response) => response.data);
  }
}
