import repo from "./repository";
import { UserRoleModel ,ParameterModel} from "model";

export default class UserRoleRepository {
  async find(id: string): Promise<UserRoleModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "UserRole/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number, UserRoleModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "UserRole", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "UserRole/" + id)
      .then((res) => res?.data);
  }
  async save(entity: UserRoleModel): Promise<UserRoleModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "UserRole", entity)
      .then((response) => response.data);
  }

  async update(id: string, entity: UserRoleModel): Promise<UserRoleModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "UserRole/" + id, entity)
      .then((response) => response.data);
  }
}
