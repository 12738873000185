import ProtectedRoute from 'Admin/components/permission/protectedRoute'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { useUserSelector } from 'state/hook'

import { lazy } from 'react'
import BankTransaction from 'finance/features/bankTransaction/bankTransaction'
import DayBookReport from 'reports/dayBook'
import DipSalesReport from 'reports/dipSalesReport'
import SalesByVehicleReport from 'reports/saleByVehicleReport'
import Sales from 'Sales/features/sales/sales'




const Home= lazy(() => import('home'))
const DashBaord= lazy(() => import('dashboard/layout'))
const LoginPanel= lazy(() => import('Admin/components/login/loginPanel'))
const UserCompany= lazy(() => import('Admin/features/userCompany/userCompany'))
const Company= lazy(() => import('Admin/features/company/company'))
const Machine= lazy(() => import('vat/features/machine/machine'))
const VatTransaction= lazy(() => import('vat/features/vatTransaction/vatTransaction'))
const PurchaseOrder= lazy(() => import('purchase/features/purchaseOrder/purchaseOrder'))
const Supplier= lazy(() => import('purchase/features/supplier/supplier'))
const Receive= lazy(() => import('purchase/features/receive/receive'))
const DocumentSetting= lazy(() => import('setting/features/documentSetting/documentSetting'))
const Setting= lazy(() => import('setting/features/setting/setting'))
const Expense= lazy(() => import('vat/features/expense/expense'))
const TaxType= lazy(() => import('vat/features/taxType/taxType'))
const ExpenseType= lazy(() => import('vat/features/expenseType/expenseType'))
const PurchaseType= lazy(() => import('vat/features/purchaseType/purchaseType'))
const Tin= lazy(() => import('vat/features/tin/tin'))
const Purchase= lazy(() => import('vat/features/purchase/purchase'))
const Vehicle= lazy(() => import('gasStation/features/vehicle/vehicle'))

const CarWash= lazy(() => import('gasStation/features/carWash/carWash'))
const Parking= lazy(() => import('gasStation/features/parking/parking'))
const Report= lazy(() => import('reports/report'))

const Transport= lazy(() => import('purchase/features/transport/transport'))

const VatReport= lazy(() => import('vat/features/vatTransaction/report/vatReport'))
const Customer= lazy(() => import('Sales/features/customer/customer'))
const Delivery= lazy(() => import('Sales/features/delivery/delivery'))
const SalesOrder= lazy(() => import('Sales/features/SalesOrder/salesOrder'))
const PurchaseTab= lazy(() => import('purchase/features/purchase/purchase'))
const ProductTab= lazy(() => import('inventory/features/productTab/productPanel'))
const Shift= lazy(() => import('gasStation/features/shift/shift'))
const FuelTank= lazy(() => import('gasStation/features/fuelTank/fuelTank'))
const Dispenser= lazy(() => import('gasStation/features/dispenser/dispenser'))
const MeterReading= lazy(() => import('gasStation/features/meterReading/meterReading'))
const DipReading= lazy(() => import('gasStation/features/dipReading/dipReading'))

const Account= lazy(() => import('finance/features/account/account'))
const Bank= lazy(() => import('finance/features/bank/bank'))
const Cashier= lazy(() => import('finance/features/cashier/cashier'))
const AccountMapping= lazy(() => import('finance/features/accountMapping/accountMapping'))
const VoucherTransaction= lazy(() => import('finance/features/voucherTransaction/voucherTransaction'))
const AccountGroup= lazy(() => import('finance/features/accountGroup/accountGroup'))



const SignUpPanel= lazy(() => import('Admin/components/signUp/signUpPanel'))
const Module=lazy(() => import('Admin/features/module/module'))
const Operation=lazy(() => import('Admin/features/operation/operation'))
const Role=lazy(() => import('Admin/features/role/role'))
const RolePermission=lazy(() => import('Admin/features/rolePermission/rolePermission'))
const User=lazy(() => import('Admin/features/user/user'))
const UserRole=lazy(() => import('Admin/features/userRole/userRole'))
const Bincard=lazy(() => import('inventory/features/bincard/bincard'))
const DailySales=lazy(() => import('inventory/features/dailySales/dailySales'))
const PriceChange=lazy(() => import('inventory/features/priceChanges/priceChange'))
const Product=lazy(() => import('inventory/features/product/product'))
const ProductCategory=lazy(() => import('inventory/features/productCategory/productCategory'))
const Store=lazy(() => import('inventory/features/store/store'))
const DataSourcePanel=lazy(() => import('businessAnalysis/features/wizard/Panel'))
const DataSourceObject=lazy(() => import('businessAnalysis/features/dataSourceObject/dataSourceObject'))
const DataSourceObjectField=lazy(() => import('businessAnalysis/features/dataSourceObjectField/dataSourceObjectField'))
const Section=lazy(() => import('businessAnalysis/features/section/section'))
const SectionItem=lazy(() => import('businessAnalysis/features/sectionItem/sectionItem'))
const SectionUser=lazy(() => import('businessAnalysis/features/sectionUser/sectionUser'))
const SectionTab=lazy(() => import('businessAnalysis/components/tab/sectionTab'))



const HomeRoutes = () => {
  const { user } = useSelector(useUserSelector)

  return (
    <Routes>
      <Route path="/login" element={<LoginPanel />}></Route>
      <Route path="/signup" element={<SignUpPanel />}></Route>
 
      {typeof user == 'undefined' && (
        <Route path="" element={<LoginPanel />}></Route>
      )}
      {typeof user != 'undefined' && (
        <Route
          path="/"
          element={
            <ProtectedRoute
              user={user}
              children={<DashBaord></DashBaord>}
            ></ProtectedRoute>
          }
        >
          <Route path="home" element={<Home />} />
          <Route path="" element={<Home />} />
         
          <Route path="module" element={<Module />} />
          <Route path="role" element={<Role />} />
          <Route path="operation" element={<Operation />} />
          <Route path="rolePermission" element={<RolePermission />} />
          <Route path="userRole" element={<UserRole />} />
          <Route path="userCompany" element={<UserCompany />} />
          <Route path="company" element={<Company />} />
          <Route path="user" element={<User />} />
          <Route path="itemCategory" element={<ProductCategory />} />
          <Route path="item" element={<Product />} />
          <Route path="store" element={<Store />} />
          <Route path="bincard" element={<Bincard />} />
          <Route path="dailySales" element={<DailySales />} />
          <Route path="priceChange" element={<PriceChange />} />
          <Route path="machine" element={<Machine />} />
          <Route path="vatTransaction" element={<VatTransaction />} />
          <Route path="vatReport" element={<VatReport />} />
          <Route path="taxType" element={<TaxType />} />
          <Route path="expenseType" element={<ExpenseType />} />
          <Route path="purchaseType" element={<PurchaseType />} />
          <Route path="tin" element={<Tin />} />
          <Route path="expense" element={<Expense />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="purchaseOrder" element={<PurchaseOrder></PurchaseOrder>} />
          <Route path="salesOrder" element={<SalesOrder />} />
          <Route path="supplier" element={<Supplier />} />
          <Route path="customer" element={<Customer />} />
          <Route path="receive" element={<Receive />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="documentSetting" element={<DocumentSetting />} />
          <Route path="sales" element={<Sales />} />
          <Route path="purchaseTab" element={<PurchaseTab />} />
          <Route path="productTab" element={<ProductTab />} />
          <Route path="shift" element={<Shift />} />
          <Route path="fuelTank" element={<FuelTank />} />
          <Route path="dispenser" element={<Dispenser />} />
          <Route path="meterReading" element={<MeterReading />} />
          <Route path="dipReading" element={<DipReading />} />
          <Route path="account" element={<Account/>} />
          <Route path="bank" element={<Bank/>} />
          <Route path="cashier" element={<Cashier/>} />
          <Route path="accountMapping" element={<AccountMapping/>} />
          <Route path="voucherTransaction" element={<VoucherTransaction/>} />
          <Route path="accountGroup" element={<AccountGroup/>} />
          <Route path="bankTransaction" element={<BankTransaction/>} />
          <Route path="carWash" element={<CarWash/>} />
          <Route path="parking" element={<Parking/>} />
          <Route path="dayBookReport" element={<DayBookReport/>} />
          <Route path="dipSalesReport" element={<DipSalesReport/>} />
          <Route path="vehicle" element={<Vehicle/>} />
          <Route path="salesByVehicleReport" element={<SalesByVehicleReport/>} />
          <Route path="report" element={<Report/>} />
          <Route path="transport" element={<Transport/>} />
          <Route path="setting" element={<Setting/>} />
          <Route path="dataSource" element={<DataSourcePanel />} />
          <Route path="dataSourceObject" element={<DataSourceObject />} />
          <Route
            path="dataSourceObjectField"
            element={<DataSourceObjectField />}
          />
          <Route path="section" element={<Section />} />
          <Route path="sectionItem" element={<SectionItem />} />
          <Route path="sectionUser" element={<SectionUser />} />
          <Route path="businessAnalysis" element={< SectionTab/>} />
        </Route>
      )}
    </Routes>
  )
}

export default HomeRoutes
