
import { Modal } from 'antd'


interface Props {
  title: string
  content?: string
  data:any,
  onOk:Function
}

const ConfirmField = (props: Props) => {
  const { title, onOk,data, content } = props

  return Modal.confirm({
    title: title,
    content: content,
    okText: 'Ok',
    cancelText: 'Cancel',
    onOk: ()=>{onOk(data)},
  })
}
ConfirmField.defaultProps = {
  title: 'Action',
  content: 'are you sure?',
  isEditable: true,
}

export default ConfirmField
