
import { UserModel ,ParameterModel, SignupModel} from "model";
import repo from "./repository";

export default class UserRepository {
  async find(id: string): Promise<UserModel> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "User/" + id)
      .then((res) => res?.data);
  }
  async findAll(param: ParameterModel): Promise<[number, UserModel[]]> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "User", { params: param })
      .then((res) => res?.data);
  }
  async count(): Promise<number> {
    return await repo
      .get(process.env.REACT_APP_API_URL + "GetCount")
      .then((res) => res?.data);
  }
  async delete(id: string): Promise<any> {
    return await repo
      .delete(process.env.REACT_APP_API_URL + "User/" + id)
      .then((res) => res?.data);
  }
  async save(entity: UserModel): Promise<UserModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "User", entity)
      .then((response) => response.data);
  }
  async saveSignup(entity: SignupModel): Promise<SignupModel> {
    return await repo
      .post(process.env.REACT_APP_API_URL + "User/Signup", entity)
      .then((response) => response.data);
  }
  async updateUserCompany(id: string, companyId: string): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    queryParams.append("companyId", companyId);
    return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/UpdateUserCompany?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }
  async login(userName: string, password: string): Promise<any> {
    return await repo
    .post(process.env.REACT_APP_API_URL + "Login", {userName:userName,password:password})
      .then((response) => response.data);
  }

  async ResetPassword(
    id: string,
    password: string,
    confirmPassword: string
  ): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    queryParams.append("password", password);
    queryParams.append("confirmPassword", confirmPassword);
    return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/ResetPassword?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }
  async ConfirmEmail(
    id: string,
  ): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
     return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/ConfirmEmail?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }
  async ChangePassword(
    id: string,
    password: string,
    confirmPassword: string,
    oldPassword: string
  ): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    queryParams.append("password", password);
    queryParams.append("confirmPassword", confirmPassword);
    queryParams.append("oldPassword", oldPassword);
    return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/ChangePassword?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }
  async ResetToDefaultPassword(id: string): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/ResetToDefaultPassword?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }

  async SendEmailConfirmation(id: string): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/EmailConfirmation?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }
  async SendForgetPassword(email: string): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("email", email);
    return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/ForgetPassword?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }
  async ActivateAccount(id: string): Promise<any> {
    const queryParams = new URLSearchParams();
    queryParams.append("id", id);
    return await repo
      .put(
        process.env.REACT_APP_API_URL +
          "User/ActivateUser?" +
          queryParams.toString()
      )
      .then((response) => response.data);
  }
  async update(id: string, entity: UserModel): Promise<UserModel> {
    return await repo
      .put(process.env.REACT_APP_API_URL + "User/" + id, entity)
      .then((response) => response.data);
  }
}
